import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';

function OnBoarding() {
  const { topData, setTopData } = useTopDataStore();
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val });
  };  
  
  const copyEmail = () => {
    if (topData.inputEmail && !topData.userEmail) {
      setTopData({ ...topData, userEmail : topData.inputEmail });
    }
  };
  
  const onBoard = () => {
    if (topData.userPassword === topData.confirmPassword) {
      if (topData.inputEmail && topData.inputEmail.match(/\w@\w/)) {
        if (topData.userEmail && topData.inputEmail.match(/\w@\w/)) {
          if (topData.userName && topData.userName.match(/\w\w/)) {
            if (topData.userPassword && topData.userPassword.match(/\S{8}/) && topData.userPassword.match(/[\d\W]/)) {
              setTopData({ 
                ...topData, 
                error : "",
                userPasswordClass : "",
                confirmPasswordClass : "",
                inputEmailClass : "",
                userEmailClass : "",
                userNameClass : "",
                loading : true,
              });

              fetch(topData.endpoint + "/permission", {
                method : "PUT",
                body : JSON.stringify({
                  inviteInfo : topData.inviteInfo,
                  inputEmail : topData.inputEmail,
                  userEmail  : topData.userEmail,
                  userName   : topData.userName, 
                  password   : topData.userPassword
                })
              })
              .then((res) => {
                if (200 === res.status) {
                  alert('You have successfully been added to the document');
                  window.location.href = window.location.href.split('?')[0];
                }
                else {
                  res.json().then((data) => {
                    if (data.message) {
                      setTopData({ 
                        ...topData, 
                        error : data.message,
                        userPasswordClass : "",
                        confirmPasswordClass : "",
                        inputEmailClass : "",
                        userEmailClass : "",
                        userNameClass : "",
                        loading : false,
                      });                    
                    }
                    else {
                      setTopData({ 
                        ...setTopData, 
                        error : "Unknown Error (code 75)",
                        userPasswordClass : "",
                        confirmPasswordClass : "",
                        inputEmailClass : "",
                        userEmailClass : "",
                        userNameClass : "",
                        loading : false,
                      });                    
                    }
                  })
                }
              })
              .catch((err) => {
                console.log(err);
                setTopData({ 
                  ...setTopData, 
                  error : "Unknown Error (code 94)",
                  userPasswordClass : "",
                  confirmPasswordClass : "",
                  inputEmailClass : "",
                  userEmailClass : "",
                  userNameClass : "",
                  loading : false,
                });                    
              });              
            }
            else {
              setTopData({ 
                ...topData, 
                error : "Please use a strong password with letters, numbers, upper case and lower, perhaps a special character or two",
                userPasswordClass : "is-invalid",
                confirmPasswordClass : "is-invalid",
                inputEmailClass : "",
                userEmailClass : "",
                userNameClass : "",
              });
            }
          }
          else {
            setTopData({ 
              ...topData, 
              error : "Please enter your name",
              userPasswordClass : "",
              confirmPasswordClass : "",
              inputEmailClass : "",
              userEmailClass : "",
              userNameClass : "is-invalid",
            });
          }
        }
        else {
          setTopData({ 
            ...topData, 
            error : "Please enter the email address you will use to log in",
            userPasswordClass : "",
            confirmPasswordClass : "",
            inputEmailClass : "",
            userEmailClass : "is-invalid",
          });
        }
      }
      else {
        setTopData({ 
          ...topData, 
          error : "Please confirm the email address to which your invite was sent",
          userPasswordClass : "",
          confirmPasswordClass : "",
          inputEmailClass : "is-invalid",
        });
      }
    }
    else {
      setTopData({ 
        ...topData, 
        error : "Passwords Do Not Match",
        userPasswordClass : "is-invalid",
        confirmPasswordClass : "is-invalid",
      });
    }
  };

  // UI components for the function
  return (
    <Container className="p-3">
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Enter the email address to which your invite was sent.</Form.Label> 
          <Form.Text className="text-muted"> (You can choose a different email to set up your account in the next field if you like.)</Form.Text>
          <Form.Control type="email" name="inputEmail" onChange={formAction} value={topData.inputEmail} className={topData.inputEmailClass} onBlur={copyEmail} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Email Address to use as your Login</Form.Label> 
          <Form.Text className="text-muted"> (Must be a deliverable email address, as you will receive confirmation codes.)</Form.Text>
          <Form.Control type="email" name="userEmail" onChange={formAction} value={topData.userEmail} className={topData.userEmailClass} />
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label>Your Name</Form.Label> 
          <Form.Control type="text" name="userName" onChange={formAction} value={topData.userName} className={topData.userNameClass} />
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label>Choose a Strong Password</Form.Label>
          <Form.Control type="password" name="userPassword" onChange={formAction} value={topData.userPassword} className={topData.userPasswordClass} />
        </Form.Group>
        
        <Form.Group className="mb-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type="password" name="confirmPassword" onChange={formAction} value={topData.confirmPassword} className={topData.confirmPasswordClass} />
        </Form.Group>
        
        <Button onClick={onBoard} variant="success">
          {topData.loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {!topData.loading && "Accept Invite & Create Account"}
        </Button>  
        
        <Row style={{"marginTop":"15px"}}>
          <Col>
            {topData.error && (
              <Alert variant="danger">
                {topData.error}
              </Alert>              
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default OnBoarding;