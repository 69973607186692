import { useEffect, useRef } from "react";
import TopDataStoreProvider, { useTopDataStore } from "./TopDataStoreProvider";
import Login from "./Login";
import ConfirmLoginCode from "./ConfirmLoginCode";
import Wrapper from "./Wrapper";
import Offline from "./Offline";
import WorkspaceDataStoreProvider from "./WorkspaceDataStoreProvider";
import VariableDataStoreProvider from "./VariableDataStoreProvider";
import TemplateDataStoreProvider from "./TemplateDataStoreProvider";
import DocumentDataStoreProvider from "./DocumentDataStoreProvider";

function Main() {
  const { topData, setTopData } = useTopDataStore();
  const initialized = useRef(false);
  
  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });   
    if (params.code) {
      const [userId, invitee, ts, document, company, permission, groupName, hashed, password] = atob(params.code).split('|');
      setTopData({ 
        ...topData, 
        page : "newuser",
        inviteInfo : {
          'userId'     : userId,
          'invitee'    : invitee,
          'ts'         : ts,
          'document'   : document,
          'hashed'     : hashed,
          'companyId'  : company,
          'permission' : permission,
          'groupName'  : groupName,
          'groupPassword' : password,
        },
        inputEmailClass : '',
        userEmailClass : '',
        userNameClass : '',
        userPasswordClass : '',
        confirmPasswordClass : '',
      });
    } 
  }, [setTopData, topData]);
  
  switch (topData.page) {
    case "edit":
    case "existing":
    case "share":
    case "newuser":
    case "profile":
    case "welcome":
    case "manageDocuments":
    case "manageSpecificDocument":
    case "upload":
    case "titlePage":
    case "synopsis":
    case "templates":
    case "templateSetup":
    case "templateText":
    case "templateTitlePage":
    case "templateTitleLayout":
    case "templateTitleEdit":
    case "documentPageDisambiguate":
    case "templateSaveChoices":
    case "variables":
    case "createDocOutlet":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':
    case 'documentList':
      return <Wrapper />;    
    case "confirm":
      return <ConfirmLoginCode />;
    case "offline":
      return <Offline />;
    default:
      return <Login />;
  }
}

function App() {
  return (
    <WorkspaceDataStoreProvider>
      <TopDataStoreProvider>
        <DocumentDataStoreProvider>
          <TemplateDataStoreProvider>
            <VariableDataStoreProvider>
              <Main />
            </VariableDataStoreProvider>
          </TemplateDataStoreProvider>
        </DocumentDataStoreProvider>
      </TopDataStoreProvider>
    </WorkspaceDataStoreProvider>
  );
}

export default App;
