import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore } from "./TopDataStoreProvider";
import { useWorkspaceDataStore } from "./WorkspaceDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import { Editor } from '@tinymce/tinymce-react';
import { Button, Spinner, Container, Row, Col, Alert, Card } from 'react-bootstrap';

function Sections() {
  const { topData, setTopData } = useTopDataStore();
  const { documentData, setDocumentData } = useDocumentDataStore();

  const editorRef = useRef(null);

  // This function allows a user to start editing a section, marking that section as the live one
  // QUESTION: Why is it taking both a sectionNumber and an id?
  const editSection = (sectionNumber, id) => {
    if( !documentData.liveSection ) {
      setDocumentData({
         ...documentData, 
         liveSection : sectionNumber, 
         liveId : id
      });    
    }
    // If another section is being edited, require the user to close that one first.
    else {
      alert(
        `Please Finish Editing & Saving Section ${documentData.liveSection} 
        before Editing ${sectionNumber}`
      );
    }
  };

  // Function takes image blob info and [?] progress and returns [?]
  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    // Promise resolves if image successfully uploads and rejects with an error otherwise
    return new Promise((resolve, reject) => {
      const url = topData.endpoint + '/image';
      fetch(url, {
        method : "POST",
        body : JSON.stringify({
          email : topData.userEmail,
          token : topData.token,
          fileName : blobInfo.filename(),
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId); 
              // @Wendy: Are there security implications to this AWSAccessKey being right here
              // Should we make an IAM policy for this that only allows the one action?
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            // This sends the request to the S3 bucket
            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                // If the request is successful, retrieves the image.
                const url = topData.endpoint +  "/image?" + 
                  "email=" + topData.userEmail + 
                  '&userId=' + topData.userId + 
                  "&filename=" + blobInfo.filename()  + 
                  '&Key=' + topData.AccessKeyId + 
                  '&Secret=' + topData.SecretAccessKey + 
                  '&nonce=' + topData.nonce + 
                  '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Error uploading image.'); 
                      }      
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting image file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading image file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading image file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error uploading image file.'); 
            }        
          })            
        }
      })
    }); 
  };

  const editableText = (sectionNumber, s) => {
    // If section is in edit mode, return the editor
    if ('liveSection' in documentData && sectionNumber === documentData.liveSection) {
      return (
        <div className="editorBox">
        <Editor
          tinymceScriptSrc={'/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={s.html || ""}
          init={{
            branding: false,
            height: 500,
            menubar: false,
            // TO DO: Add variable plugin
            plugins: [
              'advlist', 'lists', 'image', 'charmap',
              'anchor', 'searchreplace', 'visualblocks', 
              'insertdatetime', 'media', 'table'
            ],
            toolbar: 'undo redo | h1 h2 h3 | ' +
              'bold italic | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | table | image',
              
            images_upload_handler: s3_image_upload_handler,
            file_picker_types: 'image',
            image_advtab: true,
            image_uploadtab: true,
            images_file_types: 'jpeg,jpg,png,gif',
            images_upload_url: 'https://example.com',
            content_css: '/tiny.css',
            init_instance_callback: (editor) => {
              editor.on('mousedown', (e) => {
                setDocumentData({ ...documentData, saved : false });
              });
            }          
          }}      
        />   
        </div>         
      );
    }
    // If section is not in editor mode, return the html blob
    else if (s.html) {  
      // temporary, conversion from underline to highlight
      if (s.html) {     
        s.html = s.html.replace(
          /text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 
          'background-color: #74FBEA;' );          
      }

      return (
        <Card.Text className="templateTextPreview" dangerouslySetInnerHTML={{__html: s.html}} />);
    }
    else {
      return (<></>);
    }
  };

  // Helper code to assist in displaying variable values in saveSection
  const isNumeric = (str) => {
    if (typeof str !== "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }
  
  // 
  const saveSection = (e) => {
    e.preventDefault();
    
    setDocumentData({ ...documentData, saving: true });
    
    if (editorRef.current) {
      // Highlight all variables in the doc with a span style.
      const innerContent = editorRef.current.getContent()
                       .replace(/^\s+|\s+$/g, '')
                       .replace(/<\/span>(\w)/g, '</span> $1')
                       .replace(/(\w)<span /g, '$1 <span ');

      const recursiveUpdate = (sections, updatedVariables) => {
        sections.forEach((s) => {
          if (s.id === documentData.liveId) {
            s.html = innerContent;
            
            // Replace all variable definitions with their associated value
            const m = [ ...s.html.matchAll(/data-tag="([^"]+)">([^<]+)</g) ];
            if (m.length) {
              m.forEach((match) => {
                const tag = match[1];
                const val = match[2];

                const t = tag.match(/\.\d+$/);
                const p = tag.match(/\./g).length;
                if (t && p > 1) {
                  // these are variable references to multiple instances of the definition                  
                  const v = tag.split('.');
                  const e1 = documentData.variableValues[v[1]];
                  const e = (Array.isArray(e1)) ? e1 : [e1];
                  const placeholder = documentData.variableData[v[0]]?.variables.reduce((prev, curr) => {
                    if (curr.id === v[1]) {
                      return curr.placeholder;
                    } else {
                      return prev;
                    }
                  }, "");
                  if (isNumeric(v[2])) {
                    const c = parseInt(v[2]);
                    let end = e.length;
                    if (c >= e.length) {
                      end = c;
                    }
                    for (let i = 0; i < end; i++) {
                      if ((i + 1) === c) {
                        e[i] = val;
                      } else {
                        if (!e[i]) {
                          e[i] = `${placeholder}.${(i + 1)}`;
                        }
                      }
                    }
                    updatedVariables[v[1]] = e;
                  }
                } else {
                  const v = tag.split('.')[1];
                  if (documentData.variableValues[v] !== val) {
                    updatedVariables[v] = val;
                  }
                }
              });              
            }            
          }
          else if (s.sub_sections) {
            recursiveUpdate(s.sub_sections, updatedVariables)
          }
        });
        
        return sections;
      }
      
      const updatedVariables = {};
      const sections = recursiveUpdate(documentData.sections, updatedVariables);
            
      const mergedVariables = {};
      Object.keys(documentData.variableValues).forEach((vid) => {
        if (vid in updatedVariables) {
          mergedVariables[vid] = updatedVariables[vid];
        }
        else {
          mergedVariables[vid] = documentData.variableValues[vid];
        }
      });
      
      // Write the updated section to fetch
      fetch(topData.endpoint + '/document', {
        method : "PUT",
        body : JSON.stringify({
          email : topData.userEmail,
          token : topData.token,
          userId : topData.userId,
          userName : topData.userName,
          id : documentData.documentId,
          version : documentData.version,
          template_id : documentData.template_id || topData.template_id,
          template_version : documentData.template_version || topData.template_version,
          variableValues : mergedVariables,
          documentName : documentData.documentName,
          sections : sections,
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
        })
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error saving document section.'); 
            }       
          })
        }
        else if (200 === res.status) {
          setDocumentData({
            ...documentData,
            liveSection : 0, 
            liveId : 0,
            saving : false, 
            sections : sections,  
            variableValues : mergedVariables,   
          });
        }   
      })
      .catch((err) => {
        setDocumentData({ ...documentData, saving : false, jumpto : "", });
        setTopData({ ...topData, loading : false, });
        console.log(err);
        alert("Error: 300-DataForm.");
      });
    }
    else {
      alert("No editorRef.");
    }
  };

  // Defines layout for each section depending on whether it is being edited or not
  const recursiveSections = (sections, prefix, cards) => {
    let count = 0;
    
    sections.forEach((s) => {
      count++;
      const sectionNumber = `${prefix}${count}`;
      cards.push(
        <Row 
          key={`section${sectionNumber}`}
          className="docEditSection docSection" 
        >
          <Col xs={2} className="docCol1">
          {
            ('liveSection' in documentData && sectionNumber === documentData.liveSection) ?
              <Button 
                variant="outline-success" 
                onClick={saveSection}
                className="docEditSaveButton docSaveButton"
              >
                {
                  documentData.saving ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />          
                  ) : (<>Save Section {sectionNumber}</>)
                }       
              </Button>
            : 
            <Button 
                onClick={() => editSection(sectionNumber, s.id)} 
                variant="outline-primary"
                className="docEditButton docSaveButton docEditSaveButton"
            >
              Edit Section {sectionNumber}
            </Button>            
          }
          
          </Col>
          <Col xs={10} className="docCol2">
            <Card.Header className="docCardHead docSectionTitle">
              {sectionNumber} {s.name}
            </Card.Header>
            <Card.Body className="docCardBody">
            {
              s.guidance ? 
                <Card.Subtitle 
                  className="guidanceTextPreview" 
                  dangerouslySetInnerHTML={{__html: `<em>${s.guidance}</em>`}} />
              : <></>
            }
            {editableText(sectionNumber, s)}
            </Card.Body>
          </Col>
        </Row>      
      );

      if (s.sub_sections) {
        recursiveSections(s.sub_sections, `${sectionNumber}.`, cards);
      }
    });
  }
  
  // Define layout for Sections
  const cards = [];
  recursiveSections(documentData.sections, "", cards);
  return cards;
} 

export default function EditDoc() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  
  const setupPage = () => {

    // Recursive function that replaces variable definitions with variable values throughout all sections
    const recurseSub = (sections) => {
      sections.forEach((s) => {
        if (s.html && s.html.length && s.variable_sets && s.variable_sets.length) {
          
          // temporary, conversion from underline to highlight
          if (s.html) {     
            s.html = s.html.replace(/text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 'background-color: #74FBEA;' );          
          }
          
          // Here I removed the deadVar checks on 9-30-24. Add in if they are causing issues.
                
          // Cycles through variable sets in the section to xxx
          s.variable_sets.forEach((vset) => {
            if (documentData.variableData[vset.id]) {
              documentData.variableData[vset.id].variables.forEach((v) => {
                if (documentData.variableData[vset.id].multiple) { 
                  let i = 0;
                  try {
                    if (Array.isArray(documentData.variableValues[v.id])) {
                      documentData.variableValues[v.id].forEach((vi) => {
                        i++;
                        if (documentData.variableValues[v.id][(i-1)]) {
                          const replaceMe = `data-tag="${vset.id}\\.${v.id}\\.${i}">[^<]+?<`;
                          const re = new RegExp(replaceMe, "mg");
                          s.html = s.html.replace(re, `data-tag="${vset.id}.${v.id}.${i}">${documentData.variableValues[v.id][(i-1)]}<`);                  
                        }
                      });
                    } else {
                      throw new Error("variableValues is not an array");
                    }
                  } catch (error) {
                    if (String(error).match(/map is not a function/)) {
                      setTimeout(() => {
                        setDocumentData({
                          ...documentData,
                          variableValues: { ...documentData.variableValues, [v.id]: [documentData.variableValues[v.id]] },
                        });
                      }, 500);
                    }
                    return false;
                  }

                  const placeholder = documentData.variableData[vset.id].variables.reduce((prev, curr) => {
                    if (curr.id === v.id) {
                      return curr.placeholder;
                    } else {
                      return prev;
                    }
                  }, "");
                  const e = documentData.variableValues[v.id];
                  const end = e.length;
                  for (i = 0; i < end; i++) {
                    let j = i + 1;
                    if (!e[i]) {
                      e[i] = `${placeholder}.${j}`;
                    }
                  }

                  const title = placeholder.replace(/^#/, '');

                  const findCommas = `<span style="background-color: #74FBEA;".*? data-tag="${vset.id}\\.${v.id}\\.comma">[^<]+?<\\/span>`;
                  const commaRe = new RegExp(findCommas, "mg");
                  i = 0;
                  const commaList = Array.isArray(e) ? e.map((item) => {
                    const k = i + 1;
                    const l = `<span style="background-color: #74FBEA;" title="${title}.${k}" data-tag="${vset.id}.${v.id}.${k}">${e[(i)]}</span>`;
                    i++;
                    return l;
                  }).join(', ') : '';
                  s.html = s.html.replace(commaRe, commaList);

                  const findBullet = `<span style="background-color: #74FBEA;".*? data-tag="${vset.id}\\.${v.id}\\.bullet">[^<]+?<\\/span>`;
                  const bulletRe = new RegExp(findBullet, "mg");
                  i = 0;

                  const bulletList = Array.isArray(e) ? e.map((item) => {
                    const k = i + 1;
                    const l = `<li><span style="background-color: #74FBEA;" title="${title}.${k}" data-tag="${vset.id}.${v.id}.${k}">${e[(i)]}</span></li>`;
                    i++;
                    return l;
                  }).join("") : '';
                  const ul = `<ul>${bulletList}</ul>`;
                  s.html = s.html.replace(bulletRe, ul);
                } else {
                  if (documentData.variableValues[v.id]) {
                    const replaceMe = `data-tag=" (${vset.id}\\.${v.id})">[^<]+?<`;
                    const re = new RegExp(replaceMe, "mg");
                    s.html = s.html.replace(re, `data-tag="${vset.id}.${v.id}">${documentData.variableValues[v.id]}<`);
                  }
                }
              });                        
            }
          });
        }
        if (s.sub_sections) {
          recurseSub(s.sub_sections);
        }
      });     
    };
  
    let newSections = documentData.sections || [];
    recurseSub(newSections);
    setDocumentData({
      ...documentData,
      sections: newSections,
    });    
  } 
  
  // Every 7200 * 1000 or when variable values change reload the workspace documents
  useEffect(() => {    
    const now = new Date().getTime();
    if (now > (workspaceData.docs_at + 7200 * 1000)) {
      const param = [
        topData.userEmail, 
        topData.userId, 
        topData.AccessKeyId, 
        topData.SecretAccessKey, 
        topData.nonce, 
        topData.groupName
      ];        
      const url = topData.endpoint + "/document?p=" + param.join(',');
      fetch(url, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: topData.token,
        }      
      })
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
            if (data.message) {
              alert(data.message);
            } else {
              alert('Error retrieving existing documents.'); 
            }       
          });
        } else if (200 === res.status) {
          res.json().then((documentData) => {
            setWorkspaceData({ 
              ...workspaceData, 
              existing: documentData['documents'], 
              docs: documentData['documents'], 
              docs_at: now,
            });
            setupPage();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error retrieving existing documents.');
      });
    } else {
      setupPage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData.variableValues]);
 
  // Export sections as a Word document
  const makeWord = () => {
    if( documentData.liveSection ) {
      alert(`Please Finish Editing & Saving Section ${documentData.liveSection} first`);
      return false;
    }

    const clean_re = new RegExp('<(span|td) [^>]+? data-tag="[^>]+?>([^<]+?)</\\1>', "mg");

    const recurseContent = (sections, level, prefix) => {
      let count = 0;
      let content = "";
      
      sections.forEach((s) => {
        count++;
        content += `<h${level}>${prefix}${count}. ${s.name}</h${level}>`;
        if (s.html) {
          content += s.html.replaceAll(clean_re, "$2");
        }
        if (s.sub_sections) {
          content += recurseContent(s.sub_sections, (level+1), `${prefix}${count}.`);
        }
      });
      return content;
    }

    const innerContent = recurseContent(documentData.sections, 1, '');

    const HTMLcontent = 
      `<!DOCTYPE html>
        <html lang="en">
        <head>
        <meta charset="utf-8" />
        <title>Protocol Generated by Asclepia Kinetika</title>
        </head>
        <body>
        ${innerContent}
        </body>
        </html>`;
          
    setTopData({ ...topData, loading : true });    

    const url = topData.endpoint + "/word";
    fetch(url, {
      method : "POST",
      body : JSON.stringify({ 
        content : HTMLcontent.replace(/\s+/g, ' '),
        email : topData.userEmail,
        token : topData.token,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
      })
    })
    .then((res) => {
      setTopData({ ...topData, loading : true }); 
    
      if (400 === res.status) {
        res.json().then((data) => {
          console.log(data)
        });
        alert('Error writing Word document.');
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          // This is where the Word document is posted - check here if it doesn't download.
          window.location.href = data.url;
        });
      }
    })
    .catch((err) => {
      setTopData({ ...topData, loading : true }); 
      console.log(err);
    });    
  };  
  
  const makeJSON = () => {
    alert('The feature to export to a JSON is not yet implemented.');
    return false;
  };
  
  /* TO DO: Put this into the NavSide
  const gotoForm = (event) => {
    event.preventDefault();
    if( !documentData.liveSection ) {
      setTopData({ ...topData, page: "studyInformation", });   
    }
    else {
      alert(`Please Finish Editing & Saving Section ${documentData.liveSection} before changing views`);
    }
  };
  */
  
  // Define Page Layout
  return (
    <Container className="subPage">
      <Row className="subPageRow editRow">
        <Col xs="12" className="topButtonGroup">
          <Button className="intNavButton dataBtn"  
            onClick={makeWord} 
            size="sm"
          >
            {topData.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!topData.loading && "Download as Word .docx"}
          </Button>&nbsp;&nbsp;&nbsp;  
          <Button 
            onClick={makeJSON} 
            className="intNavButton dataBtn" 
          >
            Generate JSON 
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{"marginTop":"15px"}}>
          {
            documentData.saved && (
            <Alert key={'data-saved'} variant={'success'}>
              Edits Saved.
            </Alert>                    
            )
          }
        </Col>
      </Row>
      <Sections />
    </Container>
  );
}