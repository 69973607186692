import './Login.css';
import { Row, Col, Container, Button, Spinner,
    Form, FloatingLabel, Alert } from 'react-bootstrap';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider.js';
import { ErrorCircle, LogoIpsum } from "./img/Vectors.js";

export default function ConfirmLoginCode() {
  
  const { topData, setTopData } = useTopDataStore();  

  // During confirm login, lists of available Variables, Documents, and Templates
  // for the workspace are loaded to the Workspace data store.  
  // TO DO: Confirm that these lists are shallow enough to fetch here. Details should be fetched later.
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();

  // Event listener resends the confirmation code to a user's email
  const resendLink = (event) => {
    event.preventDefault();
    const url = topData.endpoint + "/login";
    fetch(url, {
      method : "POST",
      body : JSON.stringify({
        email: topData.userEmail, 
        password: topData.userPassword
      })
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setTopData({ 
            ...topData, 
            loginWarning : "A new confirmation code has been sent to your email.",
            confirmCode : "",
            lockLogin : false,
            loading : false,
          });
        })
        .catch((err) => {
          console.log(err);
          alert("Error generating confirmation code.");
        });
      }      
    })
    .catch((err) => {
      console.log(err);
      alert("Error generating confirmation code.");
    });
  }; 
  
  // Action listener validates the user confirmation code
  const sendCode = (event) => {
    event.preventDefault();
    if (!document.getElementById('floatingInput').value.match(/\d\d\d\d\d\d/)) {
      setTopData({
        ...topData,
        loginWarning : "The confirmation code is not the correct format. Please try again or click 'Resend' below.",
        confirmCode : "",
      });
      return false;
    }
  
    setTopData({ ...topData, loading: true });

    // API call to verify the user's credentials with the confirmation code
    fetch(topData.endpoint + "/login", {
      method : "PUT",
      body : JSON.stringify({
        email : topData.userEmail, 
        code : topData.confirmCode, 
        password : topData.userPassword,
        groupName : topData.groupName,
      })
    })  
    .then((res) => {
      res.json().then((data) => {
        if (401 === res.status) {       
          if (data.message) {
            setTopData({
              ...topData,
              loginWarning : data.message,
              confirmCode : "",
              lockLogin : true,
              loading : false,
            });
          }
          else {
            setTopData({
              ...topData,
              loginWarning : "Error validating confirmation code.",
              confirmCode : "",
              loading : false,
              lockLogin : false,
            });
          }            
        }  
        // If confirmation code is validated, fetch the documents for the workspace. 
        else if (200 === res.status) {
          // Update user on the loading status
          setTopData({
            ...topData,
            loginWarning : "Loading workspace documents.",
            warningType: "status",
            loading : true,
          });
          const param = [
            topData.userEmail, 
            topData.userId, 
            data.AccessKeyId, 
            data.SecretAccessKey, 
            data.nonce, 
            topData.groupName];   
          const url   = topData.endpoint + "/document?" +
            "p=" + param.join(',');
          fetch(url, {
            method : "GET",
            cache : "no-cache",
            headers : {
              Authorization : data.token,
            }      
          })
          .then((res) => {
            if (401 === res.status) { 
              res.json().then((docsData) => {
                if (docsData.message) {
                  setTopData({
                    ...topData,
                    loginWarning : docsData.message,
                    confirmCode : "",
                  });
                }
                else {
                  setTopData({
                    ...topData,
                    loginWarning : "Error retrieving workspace documents.",
                    confirmCode : "",
                  }); 
                }                          
              })
            }
            // TO DO: Confirm what deadVars are and if they need to be stored or forgotten.
            else if (200 === res.status) {
              res.json().then((docsData) => {
                const deadVars = docsData.dead_vars.reduce((prev, curr) => {
                  return { ...prev, [curr.id] : curr };
                }, {});

                // Update user on the loading status
                setTopData({
                  ...topData,
                  loginWarning : "Loading workspace templates.",
                  warningType: "status",
                  loading : true,
                });

                // API call to fetch the list of all templates in the workspace
                const url = topData.endpoint + "/template?" + 
                  "email=" + topData.userEmail + 
                  '&Key=' + data.AccessKeyId + 
                  '&Secret=' + data.SecretAccessKey + 
                  '&nonce=' + data.nonce +  
                  '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : data.token,
                  }      
                })
                .then((res) => {
                  if (401 === res.status) { 
                    res.json().then((tempsData) => {
                      if (tempsData.message) {
                        setTopData({
                          ...topData,
                          loginWarning : tempsData.message,
                          confirmCode : "",
                        });
                      }
                      else {
                        setTopData({
                          ...topData,
                          loginWarning : "Error retrieving workspace templates.",
                          confirmCode : "",
                        }); 
                      }  
                    })
                  }
                  else if (200 === res.status) {
                    res.json().then((tempsData) => { 
                      // Update user on the loading status
                      setTopData({
                        ...topData,
                        loginWarning : "Loading workspace variables.",
                        warningType : "status",
                        loading : true,
                      });
                      
                      // API call to fetch the list of all variables in the workspace
                      const url = 
                        topData.endpoint + "/defaults?" + 
                        "email=" + topData.userEmail + 
                        '&userId=' + topData.userId + // TO DO: Do we need both user id and email?
                        '&Key=' + data.AccessKeyId + // TO DO: Confirm delete
                        '&Secret=' + data.SecretAccessKey + // TO DO: Confirm delete 
                        '&nonce=' + data.nonce +  // TO DO: Confirm delete
                        '&groupName=' + topData.groupName;
                      fetch(url, {
                        method : "GET",
                        headers : {
                          Authorization : data.token,
                        }
                      })
                      .then((res) => {
                        if (401 === res.status) {
                          res.json().then((varData) => {
                            if (varData.message) {
                              setTopData({
                                ...topData,
                                loginWarning : varData.message,
                                confirmCode : "",
                              });
                            }
                            else{
                              setTopData({
                                ...topData,
                                loginWarning : "Error retrieving workspace variables.",
                                confirmCode : "",
                              });
                            }
                          })
                        }
                        // TO DO: Determine what lines are and if they are used anywhere
                        else if (200 === res.status) {
                          res.json().then((varData) => {
                            const localTimestamp = new Date().getTime();
                            setWorkspaceData({
                              ...workspaceData,
                              lines : varData.lines,  // TO DO: Review return and ensure this is just a list, not details
                              varSets : varData.variables,  // TO DO: Review return and ensure this is just a list, not details
                              docs : docsData['documents'],
                              existing : docsData['documents'], // TO DO: Investigate what this is -- replace with docs 
                              docs_at : localTimestamp,
                              deadVars : deadVars,
                              liveTemps : tempsData['templates'],
                              draftTemps : tempsData['drafts'],
                            });
                            
                            setTopData({
                              ...topData,
                              AccessKeyId : data.AccessKeyId, 
                              SecretAccessKey : data.SecretAccessKey, 
                              nonce : data.nonce, 
                              token : data.token,
                              confirmCode : "******",
                              permissions : data.permissions, 
                              userPassword : "************", 
                              userName : data.userName,
                              // Retain document info in TopData to preserve status quo. Eventually move.
                              // TO DO: Delete after document workflow is revised.
                              existing : docsData['documents'], 
                              docs_at : localTimestamp,
                              deadVars : deadVars, 
                              // Update page location and loading status to redirect to the dashbaord
                              page : "welcome",  
                              loading : false,   
                              isSaving : false,
                              loginWarning : "",
                            });
                          })
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        alert('Error retrieving workspace variables.');
                      });
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  alert('Error retrieving workspace templates.');
                });
              });
            }
          })
          .catch((err) => {
            console.log(err);
            alert('Error retrieving workspace documents.');
          });
        }    
      })
    })
    .catch((err) => {
      console.error(err);
      alert("Error validating confirmation code.");
    });
  };

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val });
  };
  
  // Define page layout
  return (
    <Container fluid className="loginCont">
    <div className="screen">
    <Row>
      <Col className="login-container align-middle">
        <Row className="loginBlock">
        <Col className="logoRow align-middle">
          <LogoIpsum />
        </Col>
        <Form onSubmit={sendCode} className="formLogin" id="confirm-screen">
          <>
          {
            (topData.loginWarning === "")  ? "" :
            <Alert 
              className="login-alert" 
              key={'danger'} 
              variant={(topData?.warningType === "status") ? 'primary' : 'danger'}>
              <ErrorCircle/>{topData.loginWarning}
            </Alert>
          }</>
          <h1 className="loginHead">
            Please enter the confirmation code sent to your email
          </h1>
          <Form.Group className="form-floating text-nowrap overflow-x-hidden mb-2">
          <FloatingLabel 
              label="Confirmation code"
            >
              <Form.Control 
                type="text"
                value={topData.confirmCode} 
                className="form-control" 
                name="confirmCode" 
                id="floatingInput" 
                placeholder="******" 
                onChange={formAction} 
                onBlur={formAction} 
                required 
              />
            </FloatingLabel>
          </Form.Group>
          <>
          {
            <Button onClick={sendCode} 
              style={{background: "#FF9B54", borderColor:"#FF9B54"}} 
              size="lg" className="log-in-button mt-3 mb-4" 
              disabled={(topData.lockLogin === true || topData.confirmCode === "") ? true : false}>
              {topData.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              {!topData.loading && "SIGN IN"}
            </Button>
          }</>
          <div className="confirm-code mt-5 align-middle confirm-code-shell">
            Didn't receive a confirmation code?
            <Button onClick={resendLink} className="confirm-code conBtn">
              Resend
            </Button>
          </div>
        </Form>
        </Row>
      </Col>
    </Row>
    </div>
  </Container>  
  );
  
}