import './App.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTopDataStore } from "./TopDataStoreProvider";
import { Plus, Profile, LogoIpsum } from "./img/Vectors";

function DocumentsNav(){
  
  // To store user credentials and site page information
  const { topData, setTopData } = useTopDataStore();
  
  const newDocument = (event) => {
      event.preventDefault();
      setTopData({
        ...topData,
        id : "",
        last_saved : 0,
        template_id : "",
        page : 'createDocOutlet',
      });
    };
   
    const documentList = (event) => {
      event.preventDefault();
      setTopData({
        ...topData,
        id : "",
        last_saved : 0,
        page : 'documentList',
      });
    }

    if (topData.permissions) {
      if (['admin', 'superuser', 'manager'].includes(topData.permissions)) {
        return (
            <NavDropdown title="Documents" id="documents" className="hNavText" >
              <NavDropdown.Item className="dropDownItem" onClick={documentList} href="#action/4.1">
                All Documents
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="dropDownItem" onClick={newDocument} href="#action/4.2">
                <Plus />Create New Document
              </NavDropdown.Item>
            </NavDropdown>
        );
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
}

function TemplatesNav(){
  const { topData, setTopData } = useTopDataStore(); 
    
  const gotoSetup = (event) => {
    event.preventDefault();
    // "Initial State a template for a new template -- so change initialState to newTemplate settings -- 
    // setTemplateData(initialState);
    setTopData({
      ...topData,
      page : "templateSetup",
    });        
           
  };

  const templates = (event) => {
    event.preventDefault();
    setTopData({
      ...topData,
      id : "", // TO DO: Eliminate this
      last_saved : 0, // TO DO: Eliminate this
      page : 'templates',
    });
  }
    
  return (
    <NavDropdown title="Templates" id="templates" className="hNavText" >
      <NavDropdown.Item className="dropDownItem" onClick={templates} href="#action/3.1">
        All Templates
      </NavDropdown.Item>
        <NavDropdown.Divider />
      <NavDropdown.Item className="dropDownItem" onClick={gotoSetup} href="#action/3.2">
        <Plus />Create New Template        
      </NavDropdown.Item>
    </NavDropdown>
  );
}

export default function HorizNav(){
  const { topData, setTopData } = useTopDataStore();

  const profile = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page : 'profile', 
      userPassword : '', 
      newPassword : '', 
      newEmail: topData.userEmail 
    });
  };

  const dashboard = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'welcome', 
    });
  };

  const man_variables = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'variables', 
    });
  };

  return (
    <Navbar className="hNavMain" sticky="top">
      <Navbar.Brand href="#home" className="hNavLogo">
        <LogoIpsum /> <span style={{fontSize:"1rem"}}>powered by <span className="kinetikaOrange">kinetika</span></span>
      </Navbar.Brand>
        <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text className="hNavText" id="hNavDash" onClick={dashboard}>
          <a href="#dash">Dashboard</a>
        </Navbar.Text>
        <TemplatesNav />
        <DocumentsNav />
        <Navbar.Text className="hNavText" id="hNavVar" onClick={man_variables}>
          <a href="#variables">Variables</a>
        </Navbar.Text>
        <Nav.Link href="#Profile" className="hNavProfile" onClick={profile}> 
          <Profile />
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}