import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";

import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { Trash, Plus, Up, Right, Left, Down } from './img/Vectors.js'
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider.js';

export default function TemplateText() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { variableData, setVariableData } = useVariableDataStore();
  const { workspaceData } = useWorkspaceDataStore();
  const { topData } = useTopDataStore();
  const editorRef = useRef(null);  

  const processingRef = useRef(false);

  const find_subsection = (subsections, id, included) => {
    subsections.forEach((s) => {
      if (id === s.id) {
        included.push(id);
        if (s.sub_sections) {
          include_subsections(s, included)
        }
      }
      else if (s.sub_sections) {
        find_subsection(s.sub_sections, id, included);
      }
    });
  };

  const include_subsections = (s, included) => {
    s.sub_sections.forEach((ss) => {
      included.push(ss.id);
      if (ss.sub_sections) {
        include_subsections(ss, included);
      }
    });
  };
  
  const unselect = (e) => {
    const id = e.target.dataset.id;

    const included = [];
    find_subsection(templateData.template_details, id, included);

    const newList = templateData.selected.reduce((prev, curr) => {
      if (curr && !included.includes(curr)) {
        prev.push(curr);
      }
      return prev;
    }, []);
    
    // Summarize?
    const narrowed = (sections) => {
      return sections.reduce((prev, curr) => {
        if (curr.id && newList.includes(curr.id)) {
          if (curr.sub_sections && curr.sub_sections.length) {
            const sub = narrowed(curr.sub_sections);
            curr.sub_sections = sub;
          }
          prev.push(curr);
        }
        return prev;
      }, []);
    };
    
    // Summarize?
    const details = narrowed(templateData.template_details);
    
    // Summarize
    const d = templateData.dirty + 1;    
    setTemplateData({
      ...templateData,
      selected : newList,
      template_details : details,
      dirty : d,
    });
  };
  
  const s3_image_upload_handler = (blobInfo, progress) => {
    // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#images_upload_url
    return new Promise((resolve, reject) => {
      fetch(topData.endpoint + '/image', {
        method  : "POST",
        body    : JSON.stringify({
          email     : topData.userEmail,
          token     : topData.token,
          fileName  : blobInfo.filename(),
          Key       : topData.AccessKeyId,  // ACCESS Delete
          Secret    : topData.SecretAccessKey, // ACCESS Delete
          nonce     : topData.nonce, // ACCESS Delete
          groupName : topData.groupName,
        })
      })
      .then((response) => {
        if (200 === response.status) {
          response.json().then((data) => {
            let postData = new FormData()
            postData.append('key', data.response.fields.key);
            postData.append('AWSAccessKeyId', data.response.fields.AWSAccessKeyId); // ACCESS Delete
            postData.append('policy', data.response.fields.policy);
            postData.append('signature', data.response.fields.signature);
            postData.append('file', blobInfo.blob(), blobInfo.filename());
            postData.append('Content-Type', blobInfo.blob().type);

            fetch(data.response.url, {
              method : "POST",
              body : postData
            })
            .then((resp) => {
              if (204 === resp.status) {
                const url = topData.endpoint + "/image?" + 
                  "email=" + topData.userEmail + 
                  '&userId=' + topData.userId + 
                  "&filename=" + blobInfo.filename()  + 
                  '&Key=' + topData.AccessKeyId + 
                  '&Secret=' + topData.SecretAccessKey + 
                  '&nonce=' + topData.nonce + 
                  '&groupName=' + topData.groupName;
                fetch(url, {
                  method : "GET",
                  cache : "no-cache",
                  headers : {
                    Authorization : topData.token,
                  }                  
                })
                .then((res) => {
                  if (200 === res.status) {
                    res.json().then((d) => {
                      resolve(d.url);
                    })
                  }
                  else {
                    res.json().then((data) => {
        
                      if (data.message) {
                        alert(data.message);
                      }
                      else {
                        alert('Error posting image.'); 
                      }         
                    })                    
                  }
                })
                .catch((error) => {
                  console.error('Error', error);           
                  reject({message: "Error getting file access.", remove: true});
                });                
              }
              else {
                reject({message: "Error uploading file.", remove: true});
              }
            })
            .catch((error) => {
              console.error('Error', error);           
              reject({message: "Error uploading file.", remove: true});
            });                
          })     
        }
        else {
          response.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error posting image.'); 
            }         
          })            
        }
      })
    }); 
  };

  const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  // BACKEND: This is too complicated: need to simplify this. 
  const move_left = (sections, id, parent, node, grandparent) => {
    if (node) {
      const reconfigure = (sections, node, grandparent) => { 
        if ('top' === grandparent) {
          const newSections = [];
          // "sections" here will be the top level list
          sections.forEach((s) => {
            if (s.id === parent.id) {
              // remove the node from it's parent's sub_sections
              const sub = parent.sub_sections.reduce((prev, curr) => {
                if (curr.id !== id) {
                  prev.push(curr);
                }
                return prev;
              }, []);
              s.sub_sections = sub;
              newSections.push(s);
              // add the node as a sibling to parent
              newSections.push(node);
            }
            else {
              newSections.push(s);
            }
          });
          return newSections;
        }
        else {
          sections.forEach((s) => {
            // (devin note)
            // we're moving node up to be a sibling of parent, nested in sub_sections of 
            // grandparent
            if (s.id === grandparent.id) {
              const newSections = [];
              s.sub_sections.forEach((p) => {
                if (p.id === parent.id) {
                  const sub = p.sub_sections.reduce((prev, curr) => {
                    if (curr.id !== id) {
                      prev.push(curr);
                    }
                    return prev;
                  }, []);
                  p.sub_sections = sub;
                  newSections.push(p);
                  newSections.push(node);
                }
                else {
                  newSections.push(p);
                }
              });

              s.sub_sections = newSections;
            }
            else if (s.sub_sections) {
              reconfigure(s.sub_sections, node, grandparent);
            }
          }); 
          return sections;               
        }
      };

      // Summarize 
      const copy = deepCopy(templateData.template_details);
      const updated = reconfigure(copy, node, grandparent);
      
      const recurse_count = (sections, out) => {
        // add the number of siblings at each level as an attribute. Used in display code. 
        let count = 0;
        sections.forEach((s) => {
          if (s && s.id) {
            if (templateData.selected.includes(s.id)) {
              count++;
            }
            if (s.sub_sections) {
              const sub = [];
              recurse_count(s.sub_sections, sub);
              s.sub_sections = sub;
            }        
          }  
          out.push(s);    
        });
  
        sections.forEach((s) => {
          if (s.id) {
            s.count = count;
          }
        });
      };

      recurse_count(updated, []);

      const d = templateData.dirty + 1;
      setTemplateData({ 
        ...templateData, 
        dirty : d,
        template_details : updated, 
      });     
    }
    else {
      if (sections) {
        sections.forEach((s) => {
          if (s.id && id === s.id) {
            move_left(sections, id, parent, s, grandparent);
          }
          else {
            if (s.sub_sections && s.sub_sections.length) {
              move_left(s.sub_sections, id, s, null, parent);
            }
          }
        });
      }
      else {
        move_left(deepCopy(templateData.template_details), id, 'top', null, null);
      }
    }
  };
    
  const move_right = (sections, id, parent, node, sibling) => {

    /*
      This function is used recursively. On first entry, all we have is the ID of the 
      section to be moved to the right, the second parameter. The first parameter is 
      initially set to the tree of all sections, templateData.template_details. When the 
      function is called recursively moving down the nested tree of sections, the parent 
      parmeter gets set so we know what level we are at. The "node" parameter gets set to 
      the section corresponding to the ID which was passed in -- this node contains all the 
      information about that section, including any sub-sections it has. The sibling 
      parameter gets set to the section which was immediately before the target section at
      the same nesting level. Moving a section "to the right" means nesting it under this 
      previous sibling, so such a sibling must exist. 
    */
  
    if (node) {
      /*
        Once we have the node identified, we can actually do the rearranging of the tree.
        This is done here by scanning through the collection of sections we were handed 
        until we found the "sibling". We tuck the section being moved under this sibling 
        by adding it to the sibling's "sub_section" array. As we continue the scan, we 
        skip over the target section (the one with an id equal to the id we were handed 
        originally), since this is the old position of that section. The results are added
        to a new array, which is either the top-level list of all sections and subsections
        or a part of the tree underneath, depending on where the sections needing to be 
        moved was situated.
      */
      const newDetails = [];

      sections.forEach((s) => {
        if (s.id && s.id === sibling.id) {
          if (s.sub_sections && s.sub_sections.length) {
            const sub = s.sub_sections;
            newDetails.push({ ...s, sub_sections : [ ...sub, node ]});
          }
          else {
            newDetails.push({ ...s, sub_sections : [ node ] });
          }
        }
        else if (s.id && s.id !== id) {
          newDetails.push(s);
        }
      });
            
      if ('top' === parent) {
        sections = newDetails;        
      }
      else {
        // in this case we have a branch of the tree, so we need to re-attach it. 
        let found = false;
        const reAttach = (sections, branch, parent) => {
          sections.forEach((s) => {
            if (s.id === parent.id && !found) {
              s.sub_sections = newDetails;
              found = true;
            }
          });
        }
        const updated = deepCopy(templateData.template_details);
        reAttach(updated, newDetails, parent);
        sections = updated;
      }
                  
      const recurse_count = (sections, out) => {
        // add the number of siblings at each level as an attribute. Used in display code. 
        let count = 0;
        sections.forEach((s) => {
          if (s && s.id) {
            if (templateData.selected.includes(s.id)) {
              count++;
            }
            if (s.sub_sections) {
              const sub = [];
              recurse_count(s.sub_sections, sub);
              s.sub_sections = sub;
            }        
          }  
          out.push(s);    
        });
  
        sections.forEach((s) => {
          if (s.id) {
            s.count = count;
          }
        });
      };

      recurse_count(sections, []);

      const d = templateData.dirty + 1;
      setTemplateData({ 
        ...templateData, 
        dirty : d,
        template_details : sections, 
      });
    }
    else {
      if (sections) {
        sections.reduce((prev, s) => {
          if (s.id && s.id === id) {
            move_right(sections, id, parent, s, prev);
            return {};
          }
          else {
            if (s.sub_sections && s.sub_sections.length) {
              move_right(s.sub_sections, id, s, null, null);
            }
            return s;
          }
        }, {});
      }   
      else {
        move_right(deepCopy(templateData.template_details), id, 'top', null, null);
      }
    }       
  };
  
  const move_up = (sections, id, parent) => {
    
    if (!sections) {
      sections = templateData.template_details;
      parent = 'top';
    }

    const newDetails = [];
    let node = null;
    let next = false;  
    sections.reverse();
    sections.forEach((s) => {
      if (s.id && s.id === id) {
        node = s;
        next = true;
      }
      else if (s.id && next) {
        newDetails.push(s);
        newDetails.push(node);
        next = false;
      }
      else if (s.id) {
        if (s.sub_sections) {
          const sub = move_up(s.sub_sections, id, s.id);
          s.sub_sections = sub.reverse();
        }
      
        newDetails.push(s);
      }    
    });
    
    const d = templateData.dirty + 1;
    if ('top' === parent) {
      newDetails.reverse();
      setTemplateData({
        ...templateData,
        template_details : newDetails,
        dirty : d,
      });   
    }
    else {
      setTemplateData({
        ...templateData,
        dirty : d,
      });
      return newDetails;
    }    
  };

  const move_down = (sections, id, parent) => {
    
    if (!sections) {
      sections = templateData.template_details;
      parent   = 'top';
    }

    const newDetails = [];
    let node         = null;
    let next         = false;  
    sections.forEach((s) => {
      if (s.id && s.id === id) {
        node = s;
        next = true;
      }
      else if (s.id && next) {
        newDetails.push(s);
        newDetails.push(node);
        next = false;
      }
      else if (s.id) {
        if (s.sub_sections) {
          const sub = move_down(s.sub_sections, id, s.id);
          s.sub_sections = sub;
        }
      
        newDetails.push(s);
      }              
    });
        
    const d = templateData.dirty + 1;
    if ('top' === parent) {
      setTemplateData({
        ...templateData,
        template_details : newDetails,
        dirty: d,
      });   
    }
    else {
      setTemplateData({
        ...templateData,
        dirty : d,
      });
      return newDetails;
    }
    
  };
  
  const save_variable = (data, next) => {
    if (data.options && "string" === typeof(data.options)) {
      const options = data.options.split(/,\s*/);
      data.options  = options;
    }
      
    fetch(topData.endpoint + '/variable', {
      method : "POST",
      body   : JSON.stringify({
        ...data,
        userId     : topData.userId,
        email      : topData.userEmail,
        token      : topData.token,   
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
      })
    })
    .then((res) => {
      if (401 === res.status) { 
        res.json().then((data) => {

          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Error saving variables.'); 
          }        
        })
      }
      else if (200 === res.status) {
        res.json().then((ret) => {
          setVariableData({
            ...variableData,
            varSets : { ...variableData.varSets, [ret.info.id] : ret.info },
            newVariable : ret.info,
          });    
                                 
          next(ret.tag, ret.info);          
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };
    
  const recurseSections = (section, s, i) => {
    let thisSublevel = 1;
    
    section.sub_sections.forEach((sec) => {
      if (sec && sec.id && sec.name) {
        if (templateData.selected.includes(sec.id)) {

          // temporary, conversion from underline to highlight
          if (sec.html) {
            sec.html = sec.html.replace(/text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 'background-color: #74FBEA;' );
          }

          // looking for variables which have been "deleted"
          if (variableData.varSets) {
            // first have to wait for variables to be defined in useEffect() backend call. 
            const regTag = /data-tag="(\w+)\./gm;
            if (sec.html) {
              for (let m of sec.html.matchAll(regTag)) {
                if (m[1]) {
                  const vs = m[1];
                  if (variableData.varSets[vs] && variableData.varSets[vs].howDelete) {
                    if ("text" === variableData.varSets[vs].howDelete) {
                      const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+)</\\1>`, "mg");
                      sec.html = sec.html.replaceAll(re, "$2");
                    }
                    else {
                      const newVS = variableData.varSets[vs].changeTo;
                      const v = variableData.varSets[newVS].variables[0];
                      
                      let newTag = `${newVS}.${v.id}`;
                      if (variableData.varSets[newVS].multiple) { newTag += '.1' }
                      const placeholder = v.placeholder;
                      
                      const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>[^<]+</\\1>`, "mg");
                      sec.html = sec.html.replaceAll(re, `<$1 style="background-color: #74FBEA;" data-tag="${newTag}">${placeholder}</$1>`);
                    }
                  }              
                }              
              }        
            }
          }
                  
          s.push(
            <Row key={sec.id} className="docSupraSection">
              <Col className="docSubSection">
                <div className="docSection">
                  {i}.{thisSublevel}.&nbsp;
                  
                  {
                    (templateData.liveID && templateData.liveID === sec.id && "title" === templateData.liveType) 
                    ?
                      <>
                        <Button variant="success" 
                            size="sm" 
                            className="docSaveButton"
                            onClick={() => saveTitle(sec.id, document.getElementById('title'+sec.id).value)}
                        >
                          save
                        </Button>
                        <Form.Control type="text" 
                          placeholder="Subsection Title" 
                          defaultValue={sec.name} 
                          data-id={sec.id} id={"title" + sec.id} 
                          className="liveSectionTitle" />
                      </>
                    : <>
                        <span className="docSectionTitle">{sec.name}</span>
                        <Button variant="secondary" 
                          size="sm" 
                          className="docSaveButton"
                          onClick={editText} 
                          data-texttype="title" 
                          data-id={sec.id}
                        >
                          Edit
                        </Button>
                        <Button data-id={sec.id} 
                          variant="light" size="sm" 
                          className="trashButton" 
                          onClick={unselect}
                        >
                          <Trash id={sec.id} />
                        </Button>
                        <Button variant="light" 
                          size="sm" 
                          className="docOrgButtons" 
                          onClick={() => move_left(null, sec.id, null, null)}
                        >
                          <Left />
                        </Button>
                        {
                          (thisSublevel > 1) ?
                            <>
                              <Button variant="light" 
                                size="sm" 
                                className="docOrgButtons"
                                onClick={() => move_right(null, sec.id, null, null, null)}
                              >
                                <Right />
                              </Button>    
                              <Button variant="light" 
                                size="sm" 
                                className="docOrgButtons"
                                onClick={() => move_up(null, sec.id, 'top')}
                              >
                                <Up />
                              </Button>    
                            </>         
                          : ""
                        }
                  
                        {
                          (thisSublevel < sec.count) ?
                            <Button variant="light" 
                              size="sm" 
                              className="docOrgButtons"
                              onClick={() => move_down(null, sec.id, null)}
                            >
                              <Down />
                            </Button>             
                          : ""
                        }
                      </>
                  }                                    
                </div>
                {
                  (templateData.liveID && templateData.liveID === sec.id && "guidance" === templateData.liveType)
                  ? 
                    <>
                      <Button variant="success" 
                        size="sm" 
                        className="docSaveButton2" 
                        onClick={saveText} 
                        data-texttype="guidance" 
                        data-id={sec.id}
                      >
                        Save
                      </Button>
                      <span className="docTextType">
                        Guidance text
                      </span>
                      <br />
                      <div className="editorBox">
                      <Editor
                        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={sec.guidance ? sec.guidance : ""}
                        init={{
                          branding: false,
                          height: 150,
                          menubar: false,
                          plugins: [
                            'advlist', 'lists', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 
                            'insertdatetime', 'media', 'table'
                          ],
                          toolbar: 'undo redo | h1 h2 h3 | ' +
                            'bold italic | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | table | image',
                          images_upload_handler: s3_image_upload_handler,
                          file_picker_types: 'image',
                          image_advtab: true,
                          image_uploadtab: true,
                          images_file_types: 'jpeg,jpg,png,gif',
                          content_css: '/tiny.css',
                          /* 
                          init_instance_callback: (editor) => {
                            editor.on('mousedown', (e) => {
                              setDocumentData({ ...documentData, saved : false });
                            });
                          }
                          */          
                        }}      
                      /> 
                      </div>                    
                    </>
                  : 
                    <>
                      <Button 
                        variant="secondary" size="sm" 
                        className="docSaveButton2" 
                        onClick={editText} 
                        data-texttype="guidance" 
                        data-id={sec.id}
                      >
                        edit
                      </Button>
                      <span className="docTextType">
                        Guidance text
                      </span>
                      <p className="guidanceTextPreview" dangerouslySetInnerHTML={{__html: sec.guidance}}></p>
                    </>
                }                
                
                {
                  (templateData.liveID && templateData.liveID === sec.id && "boilerplate" === templateData.liveType)
                  ? 
                  <>
                    <Button 
                      variant="success" 
                      size="sm" 
                      className="docSaveButton2" 
                      onClick={saveText} 
                      data-texttype="boilerplate" 
                      data-id={sec.id}>
                        Save
                    </Button>
                    <span className="docTextType">Template Text</span>
                    <br />
                    <div className="editorBox">
                    <Editor
                      tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={sec.html ? sec.html : ""}
                      init={{
                        branding: false,
                        height: 350,
                        menubar: false,
                        plugins: [
                          'advlist', 'lists', 'image', 'charmap',
                          'anchor', 'searchreplace', 'visualblocks', 
                          'insertdatetime', 'media', 'table',' asclepia-group-variables'
                        ],
                        toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                          'bold italic | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | table | image',
                        variables : workspaceData.varSets,
                        description : "",
                        process_new_variable : save_variable,
                        images_upload_handler: s3_image_upload_handler,
                        file_picker_types: 'image',
                        image_advtab: true,
                        image_uploadtab: true,
                        images_file_types: 'jpeg,jpg,png,gif',
                        content_css: '/tiny.css',
                        /* 
                        init_instance_callback: (editor) => {
                          editor.on('mousedown', (e) => {
                            setDocumentData({ ...documentData, saved : false });
                          });
                        }
                        */          
                      }}      
                    /> 
                    </div>                     
                  </>                 
                  : 
                  <>
                    <Button 
                      variant="secondary" 
                      size="sm" 
                      className="docSaveButton2" 
                      onClick={editText} 
                      data-texttype="boilerplate" 
                      data-id={sec.id}
                    >
                      edit
                    </Button>
                    <span className="docTextType">
                      Template text
                    </span>
                    <div 
                    className="templateTextPreview"
                    dangerouslySetInnerHTML={{__html: sec.html}}
                    ></div>
                  </>
                  
                }                
                
              </Col>
            </Row>
          )

          if (sec.sub_sections) {
            recurseSections(sec, s, String(i)+"."+String(thisSublevel));
          }

          thisSublevel++;
        }        
      }
    });    
  };
  
  const saveTitle = (id, val) => {
    if (!val.match(/\w/)) {
      val = '(unnamed section)';
    }
    const setVal = (id, val, sections, parent) => {
      const newDetails = [];
      sections.forEach((s) => {
        if (s.id && s.id === id) {
          s.name = val;
          newDetails.push(s);
        }
        else if (s.id) {
          if (s.sub_sections) {
            const sub = setVal(id, val, s.sub_sections, s.id);
            s.sub_sections = sub;            
          }
          
          newDetails.push(s);
        }
      });
      
      const d = templateData.dirty + 1;
      if ('top' === parent) {
        setTemplateData({
          ...templateData,
          template_details : newDetails,
          liveID: 0, 
          liveType: "",
          dirty: d,
        });
        
        window.setTimeout(() => {
          const elementId = "title" + id;
          const elem = window.document.getElementById(elementId);
          if (elem) {
            elem.focus();
          }
        }, 1);
      }
      else {
        setTemplateData({
          ...templateData,
          dirty : d,
        });
        return newDetails;
      }
    };
    
    setVal(id, val, templateData.template_details, 'top');    
  };
    
  const sections = () => {
    let s = [];
    let i = 1;
    
    templateData.template_details.forEach((section) => {
      if (templateData.selected.includes(section.id)) {
      
        // temporary, conversion from underline to highlight
        if (section.html) {
          section.html = section.html.replace(/text-decoration:\s*underline; text-decoration-color:\s*red; text-decoration-thickness:\s*4px;/g, 'background-color: #74FBEA;' );
        }
        
        // looking for variables which have been "deleted"
        if (variableData.varSets) {
          // first have to wait for variables to be defined in useEffect() backend call. 
          const regTag = /data-tag="(\w+)\./gm;
          if (section.html) {
            for (let m of section.html.matchAll(regTag)) {
              if (m[1]) {
                const vs = m[1];
                if (variableData.varSets[vs] && variableData.varSets[vs].howDelete) {
                  if ("text" === variableData.varSets[vs].howDelete) {
                    const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>([^<]+)</\\1>`, "mg");
                    section.html = section.html.replaceAll(re, "$2");
                  }
                  else {
                    const newVS = variableData.varSets[vs].changeTo;
                    const v = variableData.varSets[newVS].variables[0];
                    
                    let newTag = `${newVS}.${v.id}`;
                    if (variableData.varSets[newVS].multiple) { newTag += '.1' }
                    const placeholder = v.placeholder;
                    
                    const re = new RegExp(`<(span|td) [^>]+? data-tag="${vs}\\.[^>]+?>[^<]+</\\1>`, "mg");
                    section.html = section.html.replaceAll(re, `<$1 style="background-color: #74FBEA;" data-tag="${newTag}">${placeholder}</$1>`);
                  }
                }              
              }              
            }        
          }
        }
        
        s.push(
          <Row key={section.id} className="docSupraSection">
            <Col>
              <div className="docSection">
                {i}.&nbsp;
                
                {
                  (templateData.liveID && templateData.liveID === section.id && "title" === templateData.liveType)
                  ?
                    <>
                    <Button variant="success" 
                      size="sm" 
                      className="docSaveButton"
                      onClick={() => saveTitle(section.id, document.getElementById('title'+section.id).value)}
                    >
                      save
                    </Button>
                      <Form.Control 
                        type="text" 
                        placeholder="Section Title" 
                        defaultValue={section.name} 
                        data-id={section.id} 
                        id={"title" + section.id} 
                        className="liveSectionTitle" 
                      />
                    </>
                  : <>
                      <span className="docSectionTitle">
                        {section.name}
                      </span>
                      <Button variant="secondary" 
                        size="sm" 
                        className="docSaveButton" 
                        onClick={editText} 
                        data-texttype="title" 
                        data-id={section.id}
                      >
                        edit
                      </Button>
                      <Button 
                        data-id={section.id} 
                        variant="light" 
                        size="sm" 
                        className="trashButton" 
                        onClick={unselect}
                      >
                        <Trash id={section.id} />
                      </Button>
                      {
                      (i > 1) ? 
                        <>
                          <Button variant="light" 
                            size="sm" 
                            className="docOrgButtons" 
                            onClick={() => move_right(null, section.id, null, null, null)}
                          >
                            <Right />
                          </Button> 

                          <Button variant="light" 
                            size="sm" 
                            className="docOrgButtons"
                            onClick={() => move_up(null, section.id, 'top')}
                          >
                            <Up />
                          </Button>  
                        </>  : ""         
                      }
            
                      {
                        (i < section.count) ?
                          <Button variant="light" 
                            size="sm" 
                            className="docOrgButtons"
                            onClick={() => move_down(null, section.id, null)}
                          >
                            <Down />
                          </Button>             
                        : ""
                      }                
                    </>
                }
              </div>
              {
                (templateData.liveID && templateData.liveID === section.id && "guidance" === templateData.liveType)
                ? 
                <>
                  <Button variant="success" 
                    size="sm" 
                    className="docSaveButton2" 
                    onClick={saveText} 
                    data-texttype="guidance" 
                    data-id={section.id}
                  >
                    Save
                  </Button>
                  <span className="docTextType">
                    Guidance text
                  </span>
                  <br />
                  <div className="editorBox">
                  <Editor
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={section.guidance ? section.guidance : ""}
                    init={{
                      branding: false,
                      height: 150,
                      menubar: false,
                      plugins: [
                        'advlist', 'lists', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 
                        'insertdatetime', 'media', 'table'
                      ],
                      toolbar: 'undo redo | h1 h2 h3 | ' +
                        'bold italic | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | table | image',
                      images_upload_handler: s3_image_upload_handler,
                      file_picker_types: 'image',
                      image_advtab: true,
                      image_uploadtab: true,
                      images_file_types: 'jpeg,jpg,png,gif',
                      content_css: '/tiny.css',
                      /* 
                      init_instance_callback: (editor) => {
                        editor.on('mousedown', (e) => {
                          setDocumentData({ ...documentData, saved : false });
                        });
                      }
                      */          
                    }}      
                  /> 
                  </div>                   
                </>
                : 
                <>                
                  <Button 
                    variant="secondary" 
                    size="sm" 
                    className="docSaveButton2" 
                    onClick={editText} 
                    data-texttype="guidance" 
                    data-id={section.id}
                  >
                    edit
                  </Button>
                  <span 
                    className="docTextType">
                      Guidance text
                    </span>
                  <p className="guidanceTextPreview" dangerouslySetInnerHTML={{__html: section.guidance}}></p>
                </> 
              }              

              {
                (templateData.liveID && templateData.liveID === section.id && "boilerplate" === templateData.liveType)
                ? 
                <>
                  <Button variant="success" 
                    size="sm" 
                    className="docSaveButton2" 
                    onClick={saveText} 
                    data-texttype="boilerplate" 
                    data-id={section.id}
                  >
                      Save
                  </Button>
                  <span 
                    className="docTextType">
                      Template text
                    </span>
                    <br />
                    <div className="editorBox">
                    <Editor
                      tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={section.html ? section.html : ""}
                      init={{
                        branding: false,
                        height: 350,
                        menubar: false,
                        plugins: [
                          'advlist', 'lists', 'image', 'charmap',
                          'anchor', 'searchreplace', 'visualblocks', 
                          'insertdatetime', 'media', 'table', 'asclepia-group-variables'
                        ],
                        toolbar: 'undo redo | h1 h2 h3 | asclepia-group-variables |' +
                          'bold italic | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | table | image',
                        variables : workspaceData.varSets,
                        description : "",
                        process_new_variable : save_variable,
                        images_upload_handler: s3_image_upload_handler,
                        file_picker_types: 'image',
                        image_advtab: true,
                        image_uploadtab: true,
                        images_file_types: 'jpeg,jpg,png,gif',
                        content_css: '/tiny.css',
                        /* 
                        init_instance_callback: (editor) => {
                          editor.on('mousedown', (e) => {
                            setDocumentData({ ...documentData, saved : false });
                          });
                        }
                        */          
                      }}      
                    /> 
                    </div>                     
                </>
                : 
                <>
                  <Button 
                    variant="secondary" 
                    size="sm" 
                    className="docSaveButton2" 
                    onClick={editText} 
                    data-texttype="boilerplate" 
                    data-id={section.id}>
                      edit
                    </Button>
                  <span className="docTextType">
                    Template text
                  </span>
                  <div 
                    className="templateTextPreview"
                    dangerouslySetInnerHTML={{__html: section.html}}
                  >
                  </div>
                </>
              }              

            </Col>
          </Row>
        );
        
        if (section.sub_sections) {
          recurseSections(section, s, i);
        }
        
        i++;
      }     
    });
    
    return s;
  };
    
  useEffect(() => {
    // Access the current value with processingRef.current
    if (!processingRef.current) {
      processingRef.current = true; // Set the processing state

      const html = sections();
            
      setTemplateData({ ...templateData, sectionHtml: html, });

      // Reset the processing state when done
      processingRef.current = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData.liveID, templateData.template_details, templateData.selected, variableData.varSets]);
    
  const find_name_sections = (sections, id, name) => {
    sections.forEach((s) => {
      if (id === s.id) {
        name.push(s.name);
      }
      else if (s.sub_sections) {
        find_name_sections(s.sub_sections, id, name);
      }
    });
  }
      
  const editText = (event) => {
    event.preventDefault();
    if ( !templateData.liveID ) {
      setTemplateData({ 
        ...templateData, 
        liveID: event.target.dataset.id, 
        liveType: event.target.dataset.texttype 
      });
      
      window.setTimeout(() => {
        document.querySelector(`button[data-id='${event.target.dataset.id}']`).focus();
      }, 10);        
    }
    else {
      let sectionName = [];
      find_name_sections(templateData.template_details, templateData.liveID, sectionName);
      
      alert(`Please finish editing the ${templateData.liveType} text for the ${sectionName[0]} section.`);
    }
  };
  
  const saveText = (event) => {
    event.preventDefault();
    if (!variableData.varSets) { return false; }
    
    const sectionId = event.target.dataset.id;
    const texttype  = event.target.dataset.texttype;
    
    const recSet = (sections) => {
      const newSections = [];
      sections.forEach((s) => {
        if (s.id === sectionId) {
          if ("guidance" === texttype) {
            s.guidance = editorRef.current.getContent().trim();
          }
          else {
            s.html     = editorRef.current.getContent().trim();
            /* TODO: capture group isn't working properly. Figure out later and clean up code */
            const m    = s.html.match(/data-tag="([^"]+?)"/g);            
            if (m && m.length) {
              const varsets = [];
              const found   = [];

              for (const i in m) {
                const c = m[i].split('.');
                c[0] = c[0].replace(/^data-tag="/, '');
                if (c[0] in variableData.varSets && !found.includes(c[0])) {
                  varsets.push({'id':c[0], 'version':variableData.varSets[c[0]].version, 'variables':variableData.varSets[c[0]].variables});
                  found.push(c[0]);
                }                
                else {
                  let e = ""
                  for (const [k, v] of Object.entries(variableData.varSets)) {
                    if ( v.name.toLowerCase() === c[0].replace('"', '').toLowerCase() || (c[1] && v.name.toLowerCase() === c[0].replace('"', '').toLowerCase() + '.' + c[1].replace('"', '').toLowerCase()) || (c[2] && v.name.toLowerCase() === c[0].replace('"', '').toLowerCase() + '.' + c[1].replace('"', '').toLowerCase() + '.' + c[2].replace('"', '').toLowerCase()) || (c[3] && v.name.toLowerCase() === c[0].replace('"', '').toLowerCase() + '.' + c[1].replace('"', '').toLowerCase() + '.' + c[2].replace('"', '').toLowerCase() + '.' + c[3].replace('"', '').toLowerCase()) ) {
                      e = k;
                    }
                  }
                  
                  if (e && !found.includes(e)) {
                    varsets.push({'id':e, 'version':variableData.varSets[e].version, 'variables':variableData.varSets[e].variables});
                    found.push(e);
                  }
                  else if (!e && !found.includes(c[0])) {
                    varsets.push({'id':c[0], 'version':1, 'variables':[{'id':c[1], 'version':1}]});
                    found.push(c[0]);
                  }                  
                }
              }
              s.variable_sets = varsets;
            }
          }
        }        
        else if (s.sub_sections) {
          s.sub_sections = recSet(s.sub_sections);
        }
        
        newSections.push(s);
      });
      
      return newSections;
    }
    
    let newDetails = recSet(templateData.template_details);
    
    if (variableData.newVariable) {
      const updateTemplateDetails = (sections) => {
        sections.forEach((s) => {
          if (s.variable_sets) {
            let found = false;
            const variable_sets = s.variable_sets.map((vs) => {
              if (vs.id === variableData.newVariable.id) {
                found = true;
                return variableData.newVariable;
              }
              else {
                return vs;
              }
            });
            if (!found) {
              variable_sets.push(variableData.newVariable);
            }
            s.variable_sets = variable_sets;
          }
          else if (s.id === templateData.liveID) {
            s.variable_sets = [variableData.newVariable];
          }
          
          if (s.sub_sections) {
            updateTemplateDetails(s.sub_sections);
          }
        });
        return sections;
      };
      newDetails = updateTemplateDetails(newDetails);
      
      setVariableData({
        ...variableData,
        newVariable : "",
      });
    }
    
    const d      = templateData.dirty + 1;
    const edited = templateData.liveID;
    setTemplateData({ 
      ...templateData, 
      liveID : 0, 
      liveType : "",
      template_details : newDetails,
      dirty : d,
    });
    
    window.setTimeout(() => {
      document.querySelector(`button[data-id='${edited}']`).focus();
    }, 10);        
  };
  
  const addSection = (e) => {
    e.preventDefault();
    
    const num = templateData.newSectionNum + 1;
    
    const sel = [ ...templateData.selected, `newSection-${num}`, ];

    const recurse_count = (sections, newSections) => {
      let count = 0;
      sections.forEach((s) => {
        if (s.id) {
          if (sel.includes(s.id)) {
            count++;
          }
          if (s.sub_sections) {
            const sub = [];
            recurse_count(s.sub_sections, sub);
            s.sub_sections = sub;
          }        
        }      
      });
    
      sections.forEach((s) => {
        if (s.id) {
          s.count = count;
          newSections.push(s);     
        }
      });
    };
    
    const det = [ ...templateData.template_details, {
        id : `newSection-${num}`,
        version : 1,
        name : 'Newly Added Section',
        sub_sections : [],
        count : 0,
        html : "",
        guidance : "",
      }, ];
      
    let count = 0;
    det.forEach((s) => {
      if (sel.includes(s.id)) {
        count++;
      }
    });
    const newDetails = [];
    det.forEach((s) => {
      s.count = count;
      
      if (s.sub_sections) {
        const sub = [];
        recurse_count(s.sub_sections, sub);
        s.sub_sections = sub;
      }
      
      newDetails.push(s);
    });
               
    setTemplateData({
      ...templateData,
      template_details : newDetails,
      newSectionNum : num,
      selected : sel,
    });

    window.setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 10);
  };
   
  // Page layout definition begins here
  // if (variableData.varSets && !templateData.saving) { TO DO: Delete once tested that this is not required
  if (!templateData.saving) {
    return (
      <Container className="subPage">
        <Row className="subPageRow" >
          {/* TO DO: Replace with reusable button component */}
          <p className="docLinkItem" id="newSection"><a href="#id" onClick={addSection}  >
            <Plus />Add New Section
          </a></p>
        </Row>
        { // If the template has populated sections, display them.
           
          templateData.sectionHtml ? 
            templateData.sectionHtml : // The UI for the template sections is defined in an HTML blob stored in the templateData
            ""
        }
      </Container>  
    );  
  }
  // Display a spinner while the template loads
  else {
    return (
      <Container className="subPage">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />    
        <p className="loadWarning">
          Template is loading from the server. 
          If this takes longer than it should, contact aimee.harrison@asclepiagroup.com.
        </p>
      </Container>
    );
  }
}