import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore, emptyState } from "./TemplateDataStoreProvider";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useEffect } from 'react';

import { Plus, Trash } from "./img/Vectors";
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider';

function LiveTemplateList() {
  const { topData, setTopData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  
  const openLiveTemplate = (event, id, version, name) => {
    event.preventDefault();

    setTemplateData({
      ...templateData,
      footer : true,
      template_id : id,
      template_version : version,
      draft_id : "",
      dirty : 0,
      loading : false,
      description: "ADD DESCRIPTION TO openLiveTemplate in TemplatesList",
      name : name,
      templateType : "live",
    });
    setTopData({
      ...topData,
      loading: false,
      isSaving: false, 
      loginWarning: "",
      page : "templateSetup",
    })
  };
    
  const deleteTemplate = (e, id, name, version) => {
    e.preventDefault();
    
    if (window.confirm(`Permanently delete template ${name} and all derived draft templates and documents?`)) {
      const templates = workspaceData.liveTemps.reduce((prev, curr) => {
        if (curr.id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      const drafts = workspaceData.draftTemps.reduce((prev, curr) => {
        if (curr.id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setWorkspaceData({
        ...workspaceData,
        liveTemps : templates,
        draftTemps : drafts,
      });
            
      fetch(topData.endpoint + '/template', {
        method : "DELETE",
        body   : JSON.stringify({
          email : topData.userEmail,
          token : topData.token,
          template_id : id,
          template_version : version,
          Key : topData.AccessKeyId, // TO DO: Confirm Delete
          Secret : topData.SecretAccessKey, // TO DO: Confirm Delete
          nonce : topData.nonce, // TO DO: Confirm Delete
          groupName : topData.groupName,
          userId : topData.userId,
        })
      })
      .then((res) => {
        if (200 !== res.status) {
          res.json().then((data) => {
    
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error permanently deleting template.'); 
            }
          
          });
        }
        else {
          alert(`Successfully deleted template ${name}.`);
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });
    }          
  };
  
  // Define list UI layout
  if (workspaceData.liveTemps.length) {
    workspaceData.liveTemps.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    return workspaceData.liveTemps.map((t) => {
      return(
        <div key={t.id} className="docListItem">
          <div className="docTextBlock">
            <a href="#id" onClick={(e) => openLiveTemplate(e, t.id, t.version, t.name)}>
              {t.name || 'Unnammed Template'}
            </a>&nbsp;
          <span className="docListItemDetail">{t.description} (version {t.version})</span>
          </div>
            <a href="/delete-template" 
              onClick={(e) => deleteTemplate(e, t.id, (t.name || 'Unnamed Template'), t.version)} 
              className="trashButtonList" >
                <Trash />            
            </a>
        </div>
      );   
    });    
  }
  else {
    return (
      <div className="welcomeText">
        No available templates. 
      </div>    
    );
  }
}

// TO DO: Turn these into reusable components
function DraftList() {
  const { topData, setTopData } = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  
  // Action event to open a selected draft
  const openDraft = (event, id, version, draft, name, description) => {
    event.preventDefault();

    // Set the template data to be able to tell the user what is currently being loaded
    setTemplateData({
      ...templateData,
      footer : true,
      template_id : id, // Does opening a draft depend on template ID or just draft iD?
      template_version : version,
      draft_id : draft,
      dirty : 0,
      draftName : name, 
      description  : description,
      templateType : "draft",
    });
    setTopData({
      ...topData,
      loading: false,
      isSaving: false, 
      loginWarning: "",
      page : "templateSetup",
    })
  
  };

  // TO DO: Add a reload to ensure the deleted draft is immediately reflected
  const deleteDraft = (e, template_id, name, version, id) => {
    e.preventDefault();
    if (window.confirm(`Permanently delete template draft ${name}?`)) {
      const drafts = workspaceData.draftTemps.reduce((prev, curr) => {
        if (curr.draft_id !== id) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setWorkspaceData({
        ...workspaceData,
        draftTemps : drafts,
      });
            
      fetch(topData.endpoint + '/template', {
        method : "DELETE",
        body   : JSON.stringify({
          email : topData.userEmail,
          token : topData.token,
          draft_id : id,
          template_id : template_id,
          template_version : version,
          Key : topData.AccessKeyId, // TO DO: Confirm delete
          Secret : topData.SecretAccessKey, // TO DO: Confirm delete
          nonce : topData.nonce, // TO DO: Confirm delete
          groupName : topData.groupName,
          userId : topData.userId,
        })
      })
      .then((res) => {
        if (200 !== res.status) {
          res.json().then((data) => {
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Experienced an error deleting the document.'); 
            }    
          });
        }
        else {
          alert(`Successfully deleted template draft ${name}.`);
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Error deleting the document.');
      });
    }          
  };

  if (workspaceData.draftTemps.length) {
    workspaceData.draftTemps.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    // Layout for draft list items
    return workspaceData.draftTemps.map((d) => {

      // Id of a draft refers to the template the draft was based off of.
      const templateName = workspaceData.liveTemps.reduce((prev, curr) => {
        if (d.id === curr.id) { return curr.name; }
        else { return prev; }
      }, ""); 

      return(
        <div key={d.id+'-'+d.draft_id+'.'+d.version} className="docListItem">
          <div className="docTextBlock">
            <a href="#id" onClick={(e) => openDraft(e, d.id, d.version, d.draft_id, d.name, d.description)}>
              {d.name || 'Unnamed Draft'} 
            </a>&nbsp;
            <span className="docListItemDetail">
              {(d.version === 0 ? 'Draft, original' : 
                ('Draft, based on version ' + d.version + ' of ' + templateName))}
            </span>
          </div>
          <a href="/delete-template" 
            onClick={(e) => deleteDraft(e, d.id, d.name, d.version, d.draft_id)}
            className="trashButtonList">
              <Trash />            
          </a>
        </div>
      );   
    });
  }
  else {
    return (
      <div className="welcomeText">
        No available drafts. 
      </div>    
    );
  }
}

export default function TemplatesList() {
  const { topData, setTopData } = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();

  // Refresh template list
  useEffect(() => {
    setTemplateData(emptyState);
    setTopData({
      ...topData,
      loading : true,
    });

    const url =
      topData.endpoint + "/template?" +
        "email=" + topData.userEmail +
        "&Key=" + topData.AccessKeyId +
        "&Secret=" + topData.SecretAccessKey +
        "&nonce=" + topData.nonce +
        "&groupName=" + topData.groupName;
    fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: topData.token,
      },
    })
    .then((res) => {
      if (401 === res.status) {
        res.json().then((tempsData) => {
        if (tempsData.message) {
            setTopData({
              ...topData,
              loading: false,
              loginWarning: tempsData.message,
            });
          } else {
            setTopData({
              ...topData,
              loading: false,
              loginWarning: "Error retrieving workspace templates.",
            });
      }
      });
    } else if (200 === res.status) {
        res.json().then((tempsData) => {
            setWorkspaceData({
              ...workspaceData, 
              liveTemps: tempsData['templates'],
              draftTemps: tempsData['drafts'],
            });
            setTopData({
              ...topData,
              loading: false,
              isSaving: false,
              loginWarning: "",
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error retrieving updated template lists.');
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

  // Action event to open the setup module for a new template
  const gotoSetup = (event) => {
  
    event.preventDefault();

    setTemplateData({
      ...templateData,
      loading : true,
    });

    // Redirect to the template outlet for the setup module 
    setTopData({
      ...topData,
      page : "templateSetup",
    });      
  }

  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">Templates</h2>
      </Row>
      <Row>
        {

          !topData.loading ? 
            <>
              <Row key="newTemplate">
                <div className="docListItem" id="newTemplate">
                  <a href="#id" onClick={gotoSetup}>
                    <Plus />Create New Template
                  </a>
                </div>
              </Row>
              <Row>   
                <Col xs={10}>
                  <h4 className="pageLevel2Head">Drafts</h4>
                  <DraftList />
                </Col>   
                <Col xs={10}>
                <h4 className="pageLevel2Head proto">Active Templates</h4>
                  <LiveTemplateList />
                </Col>    
              </Row> 
            </>
          : <><Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
            /><br/><br />
              <p className="loadWarning">
                Loading workspace templates.              
              </p>
            </>                
        }
      </Row>
    </Container>
  );
}
/* TO DO: Better error handling and user messaging. 
If the user doesn't have access to any templates this will endlessly load. */