import './App.css';
import { useEffect } from "react";
import { useTopDataStore } from "./TopDataStoreProvider";
import { useDocumentDataStore } from './DocumentDataStoreProvider';
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider';
import { Container, Row, Spinner } from 'react-bootstrap';
import { Trash, Copy } from './img/Vectors';
import AsclepiaLib from "./AsclepiaLib";

function Documents() {
  const { topData, setTopData } = useTopDataStore();
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  
  useEffect(() => {
    const companyDocuments = AsclepiaLib.deepCopy(workspaceData.docs); 
       
    companyDocuments.sort((a,b) => (a.documentName > b.documentName) ? 1 : -1);
    setWorkspaceData({
      ...workspaceData,
      companyDocuments : companyDocuments,
    });
  // eslint-disable-next-line
  }, [workspaceData.docs]);

  const gotoDocument = (event) => {
    event.preventDefault();
            
    setTopData({
      ...topData,
      page : 'documentPageDisambiguate',
    });

    setDocumentData({
      ...documentData,
      documentId : event.target.dataset.id,
    })
  };

  if ("undefined" === typeof(workspaceData.docs)) {  
    return (
      <li className="nav-item" key="waitingForDocument" style={{paddingLeft:"40px"}}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        "Loading documents list."                        
      </li>
    );
  } 
  else {
    const deleteDoc = (e, id, name) => {
      e.preventDefault();
      if (window.confirm(`Permanently delete document ${name}?`)) {
        const existing = workspaceData.docs.reduce((prev, curr) => {
          if (curr.documentId !== id) {
            prev.push(curr);
          }
          return prev;
        }, []);
        
        setWorkspaceData({
          ...workspaceData,
          docs : existing,
        });
              
        fetch(topData.endpoint + '/document', {
          method : "DELETE",
          body   : JSON.stringify({
            email      : topData.userEmail,
            token      : topData.token,
            documentId : id,
            Key        : topData.AccessKeyId,
            Secret     : topData.SecretAccessKey,
            nonce      : topData.nonce,
            groupName  : topData.groupName,
            userId     : topData.userId,
          })
        })
        .then((res) => {
          if (200 !== res.status) {
            res.json().then((data) => {
      
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Error deleting existing document.'); 
              }
              // setTopData(initialState);          
            });
          }
        })
        .catch((err) => {
          console.error(err);
          alert('Error deleting existing document.');
        });
      }      
    };
    
    const cloneDoc = (e, id, name, version) => {
      e.preventDefault();
      
      setDocumentData({
        ...documentData,
        cloning: id,
      });
      
      fetch(topData.endpoint + '/document', {
        method : "POST",
        body   : JSON.stringify({
          email      : topData.userEmail,
          token      : topData.token,
          documentId : id,
          version    : version,
          cloneName  : `Clone of ${name}`,
          Key        : topData.AccessKeyId,
          Secret     : topData.SecretAccessKey,
          nonce      : topData.nonce,
          groupName  : topData.groupName,
          userId     : topData.userId,
        })
      })
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
            data.documentData.documentId = data.id;
            setWorkspaceData({
              ...workspaceData,
              docs : [ ...workspaceData.docs, data.documentData ],
              companyDocuments : [ ...workspaceData.companyDocuments, data.documentData ],
            });
            setDocumentData({
              ...documentData,
              cloning : 0
            });
          });
        }
        else {
          res.json().then((data) => {
    
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error cloning requested document.'); 
            }
            // setTopData(initialState);          
          });
        }
      })
    };
    
    return workspaceData.companyDocuments.reduce((collection, docData) => {
      if (docData.documentId) {
        collection.push(
          <div className="docListItem" key={docData.documentId}>
            <div className="docTextBlock">
              <a href="#id" data-id={docData.documentId} onClick={gotoDocument}>
                {docData.documentName || 'Unnamed Document'}
              </a> &nbsp;
              <span className="docListItemDetail">
                Based on {docData.template_name} v. {docData.template_version}
              </span>
            </div>
            <a href="/delete-doc" 
              onClick={(e) => deleteDoc(e, docData.documentId, docData.documentName)} 
              className="trashButtonList"
            >
              <Trash />          
            </a>
            { (topData.cloning && docData.documentId === documentData.cloning) ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />      
              :
              <a href="/clone-doc"
                onClick={(e) => cloneDoc(e, docData.documentId, docData.documentName, docData.version)}
              >
                <Copy />  
              </a>
            }
          </div>
        );
      }
      return collection;
    }, []);
  }
}

export default function DocumentList() {
  return (
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          Documents
        </h2>
      </Row>
      <Row> 
        <h4 className="pageLevel2Head">
          Protocols
        </h4>
        <Documents />    
      </Row>
    </Container>
  );
}