import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useVariableDataStore } from "./VariableDataStoreProvider";
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider';
import { Container, Row, Button, Spinner, Form, Accordion, Table } from 'react-bootstrap';
import { Plus } from "./img/Vectors";
import { useEffect } from 'react';

function VariableCard(props){
  const { variableData, setVariableData } = useVariableDataStore();
  const { topData } = useTopDataStore();

  let vset = {...props?.varset};
  let editedVset = {...props?.varset};


  const newVarSet = {
    version : 1,
    variant : "",
    placeholder : "#" + editedVset.name.toUpperCase() + `newVariant`,
    select : false,
    options : [],
    example : "",
    unsaved : true,
  };

  const save = () => {
    /*
    setVariableData({
      ...variableData,
      loading : props?.vset.id,
    });

    editedVset.name = document.getElementById(`name-${editedVset.id}`).value;
    editedVset.description = document.getElementById(`description-${editedVset.id}`).value;
    editedVset.form_section = document.getElementById(`form_section-${editedVset.id}`).value;
    editedVset.multiple = document.getElementById(`multipleYes-${editedVset.id}`).checked;
    
    if (!editedVset.variables) {
      editedVset.variables = []
    }
    else {
      editedVset.variables = editedVset.variables.map((v) => {
        v.variant = document.getElementById(`variant-${v.id}`).value;
        v.placeholder = document.getElementById(`placeholder-${v.id}`).value;
        v.example = document.getElementById(`example-${v.id}`).value;
        v.select = document.getElementById(`selectYes-${v.id}`).checked;
        v.unsaved = false;
        
        const options = document.getElementById(`options-${v.id}`).value.trim();
        
        if (options) {
          const parts = options.split(',');
          v.options = parts.map((p) => {
            return p.trim();
          });
        }
        else {
          v.options = [];
        }
        return v;
      });
    }

    // Temporary to remove default variables that are not used
    for (let index = 0; index < editedVset.variables.length; index++) {
      const element = editedVset.variables[index];
      if(
        ( element.variant === newVarSet.variant || element.variant === "Default") && 
        element.version === newVarSet.version
      )
      {
        editedVset.variables = editedVset.splice(index,1);
      };

        //version : 1,
    // variant : "",
    // placeholder : "#" + editedVset.name.toUpperCase() + `newVariant`,
    // select : false,
    // options : [],
    // example : "",
     // unsaved : true,
    } 

    const url = topData.endpoint + '/variable';
    fetch(url, {
      method : "PUT",
      body : JSON.stringify({
        email : topData.userEmail,
        token : topData.token,
        vset : editedVset,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
      })
    })
    // TO DO: Review what is returned and ensure that varDefns is the full variable
    .then((response) => {
      if (200 === response.status) {     
        response.json().then((data) => {          
          setVariableData({
            ...variableData,
            edit    : "",
            loading : "",
            varDefns : { 
              ...variableData.variables,         
              [editedVset.id] : data.vset,
            }
          });
        });
      }
      else {
        response.json().then((data) => {
      
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Error saving changes to variable ', editedVset.name); 
          }     
        })            
      }
    });*/
    alert("Feature not yet implemented.");
  }; 

  const edit = () => {
    /*
    setVariableData({
      ...variableData,
      edit : vset.id,
    });
    */
    alert("Feature not yet implemented.");
  };

  const cancel = () => {
    /*
    editedVset = {...vset};
    setVariableData({
      ...variableData,
      edit : "",
    });
    */
    alert("Feature not yet implemented.");
  };

  const cancelVariant = (variant) => {
    /*
    setVariableData({
      ...variableData,
      loading : vset.id,
    });

    const index = editedVset.variables.indexOf(variant);
    editedVset.variables = editedVset.variables.splice(index,1);

    setVariableData({
      ...variableData,
      loading : "",
    });
    */
    alert("Feature not yet implemented.");
  }

  const newVariant = () => {
    /*
    setVariableData({
      ...variableData,
      loading : vset.id,
    });

    (!editedVset.numNewVariants) ? 
      ( editedVset.numNewVariants = 1) : 
      ( editedVset.numNewVariants = editedVset.numNewVariants + 1); 
    const newVar = {
      id : `${vset.id}-newVariant-${editedVset.numNewVariants}`,
      version : 1,
      variant : "",
      placeholder : "#" + editedVset.name.toUpperCase() + `newVariant-${editedVset.numNewVariants}`,
      select : false,
      options : [],
      example : "",
      unsaved : true,
    };
    editedVset.variables.push(newVar);

    setVariableData({
      ...variableData,
      loading : "",
    });
    */
    alert("Feature not yet implemented.");
  };

  const variantDetails = () => {
    return editedVset.variables.map((v) => {
      if (v) {
        return (
          <Table bordered 
              hover 
              className="tbl varT"
              key={editedVset.id + '.' + v.id}>
            <tbody className="tblBody varBody">
              <tr className="varR tblR">
                <th className="varH tblH">Variant Name</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === editedVset.id) ?
                    <>
                      <Form.Control 
                        type="text" 
                        defaultValue={v.variant || 'default'}
                        className="dataLiveType tblLiveType" 
                        id={'variant-'+v.id} 
                      />
                      {
                        (v.unsaved) ? 
                          <Button 
                            className="dataAddInstance varTempBtn" 
                            onClick={() => cancelVariant(v)}
                          >
                            Remove new variant
                          </Button> : ""
                      }
                    </>
                    : <>{v.variant || 'default'}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Placeholder</th>
                <td>
                  {
                    (variableData.edit && variableData.edit === editedVset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.placeholder} 
                      id={'placeholder-'+v.id} 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.placeholder}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Example</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === editedVset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.example} 
                      id={'example-'+v.id} 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.example}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Use Dropdown Select List?</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === editedVset.id) ?
                      <div className="mb-3">                
                        <Form.Check
                          inline
                          label="yes"
                          name={`select-${v.id}`}
                          type="radio"
                          id={`selectYes-${v.id}`} 
                          defaultChecked={v.select}                       
                        />
                        <Form.Check
                          inline
                          label="no"
                          name={`select-${v.id}`}
                          type="radio"
                          id={`selectNo-${v.id}`}
                          defaultChecked={!v.select}
                        />
                      </div>
                      : <>{v.select ? 'yes' : 'no'}</>
                  }              
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Options List (if dropdown)</th>
                <td>
                  {
                    (variableData.edit && variableData.edit === editedVset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={v.options ? v.options.join(', ') : ""} 
                      id={'options-'+v.id} 
                      placeholder="option1, option2, option3..." 
                      className="dataLiveType tblLiveType"
                    />
                    : <>{v.options ? v.options.join(', ') : ""}</>
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        );
      }
      else {
        return "";
      }
    })    
  }

  return(
    <Accordion>
      <Accordion.Item key={vset.id} eventKey={vset.id}>
        <Accordion.Header className="accHead">
          {vset.name}
        </Accordion.Header>
        <Accordion.Body className ="accBody">
          {
            (variableData.edit && variableData.edit === vset.id) ?
              <>
                <Button 
                  className="dataAddInstance varTempBtn" 
                  onClick={() => save(vset.id)}>
                    {
                      (variableData.loading && variableData.loading === vset.id) ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />  
                      : <>Save</>                    
                    }
                </Button>
                <Button 
                  className="dataAddInstance varTempBtn"
                  onClick={cancel}>
                    Cancel
                  </Button>
              </>
            :
              <Button 
                  className="dataAddInstance varTempBtn"
                  onClick={() => edit()}>
                  Edit
              </Button>
          }
          <Table 
              className="tbl varT"
              bordered 
              hover 
              key={vset.id}>
            <tbody className="varBody tblBody">
              <tr className="varR tblR">
                <th className="varH tblH">
                  Name
                </th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={vset.name} 
                      id={'name-'+vset.id} 
                    />
                    : <>{vset.name}</>
                  }
                </td>
              </tr>
              <tr className="var tbl">
                <th className="varH tblH">Version</th>
                <td>{vset.version}</td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Description</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Control 
                      type="text" 
                      defaultValue={vset.description} 
                      id={'description-'+vset.id}  
                      className="dataLiveType tblLiveType"
                    />
                    : <>{vset.description}</>
                  }                
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Section of Form</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?
                    <Form.Select name="form_section" id={'form_section-'+vset.id} defaultValue={vset.form_section}>
                      <option value="Study Information">Study Information</option>
                      <option value="Objectives">Objectives</option>
                      <option value="Study Design">Study Design</option>
                      <option value="Patient Population">Patient Population</option>
                      <option value="Variables">Variables</option>
                    </Form.Select>
                    : <>{vset.form_section}</>
                  }
                </td>
              </tr>
              <tr className="varR tblR">
                <th className="varH tblH">Allow Multiple Instances?</th>
                <td className="varD tblD">
                  {
                    (variableData.edit && variableData.edit === vset.id) ?  
                    <div className="mb-3">                
                      <Form.Check
                        inline
                        label="yes"
                        name="multiple"
                        type="radio"
                        id={`multipleYes-${vset.id}`} 
                        defaultChecked={vset.multiple}                       
                      />
                      <Form.Check
                        inline
                        label="no"
                        name="multiple"
                        type="radio"
                        id={`multipleNo-${vset.id}`}
                        defaultChecked={!vset.multiple}
                      />
                    </div>
                    : <>{vset.multiple ? 'yes' : 'no'}</>
                  }                
                </td>
              </tr>
            </tbody>
          </Table>
          <h4 className="pageLevel2Head varHead">
            Variants         
          </h4>
          <div className="tempVarBtn">
          {
            (variableData.edit && variableData.edit === vset.id) ?
            <Button 
                  className="dataAddInstance varTempBtn variant" 
              onClick={newVariant()}
            >
                Add Variant
            </Button>:""
          }
          </div>
          {variantDetails()}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default function WorkspaceVariables() {
  const { variableData, setVariableData } = useVariableDataStore();
  const { workspaceData } = useWorkspaceDataStore();

  workspaceData.varSets.sort((a, b) => b.name - a.name);

  useEffect(() => {
    setVariableData({
      ...variableData,
      varSets: workspaceData.varSets,
      varDefns: workspaceData.varDefns,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const newVariable = (e) => {
    e.preventDefault();

    const num = parseInt(variableData.newVariableSets) + 1;
    const num1 = parseInt(variableData.newVarDefs) + 1;
    // TO DO: All variables --> variable defintions (varDefns) in varSets
    const newVariableSet = {
      id: `newVariableSet-${num}`,
      name: '',
      description: '',
      version: 1,
      form_section: "Study Information",
      multiple: false,
      variables: [{
        id: `newVariableSet-${num1}-newVariant-${num1}`,
        version: 1,
        variant: '',
        placeholder: '#_NEW_VARIABLE.newVariant',
        select: false,
        options: [],
        example: ''
      }]
    };

    // TO DO: Flesh out how variable data is stored (in workspace or variable data store)
    setVariableData({
      ...variableData,
      varSets: {
        ...variableData.varSets,
        [`newVariableSet-${num}`]: newVariableSet,
      },
      newVariableSets: num,
      newVarDefs: num1,
      edit: `newVariableSet-${num}`,
    });
  };

  const variables = Object.values(variableData.varSets);
        
  // Page layout defined
  return (
    <Container className="subPage">
      <Row className="subPageRow">
        {
          // Use map safely on variables
          variables.map((v) => {
            // Ensure each VariableCard has a unique key
            return <VariableCard varset={v} key={v.id} />;
          })
        }
      </Row>
    </Container>
  );
}


/* Put add new button back in near
<p className="docLinkItem" id="newSection">
<a href="#new" onClick={newVariable}>
  <Plus />Add New Variable
</a>
</p>
*/