import './App.css';
import { useEffect, useRef } from "react";
import { Container, Row } from 'react-bootstrap';
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import NotificationsDataStoreProvider from "./NotificationsDataStoreProvider";

import TemplateOutletID from './TemplateOutletID';
import CreateDocOutlet from "./CreateDocOutlet";
import Share from "./Share";
import OnBoarding from "./OnBoarding";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import ManageDocuments from "./ManageDocuments";
import ManageSpecificDocument from "./ManageSpecificDocument";
import HorizNav from "./HorizNav";
import DocumentList from "./DocumentList";
import DocumentOutletId from './DocumentOutletId';
import TemplatesList from "./TemplatesList";
import VariableOutletId from "./VariableOutletId";
import VariableDataStoreProvider from './VariableDataStoreProvider';

function Content() {
  const { topData } = useTopDataStore();
  switch (topData.page) {
    case "variables":
      return (<VariableDataStoreProvider><VariableOutletId /></VariableDataStoreProvider>);
    // Temporarily commented out because I had trouble adding these pages.
    /*case "variablesEditor":
      return (<VariableOutletId />);
    case "variablesDash":
      return (<VariableOutletId />);
    case "variablesRequest":
      return (<VariableOutletId />);*/
    // Switch for all pages for document management or in the Document Outlet
    case "documentPageDisambiguate":
    case "edit":
    case "synopsis":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':  // TO DO: Fix the repeated use of the word "_variables" as a data category --> "characteristics"
      return (<DocumentOutletId />);
    case "manageDocuments":
      return (<ManageDocuments />);
    case "manageSpecificDocument":
      return (<ManageSpecificDocument />);
    case "documentList":
      return (<DocumentList />);
    // Switch for all pages for template management or in the Template Outlet
    case "templates":
      return (<TemplatesList />);
    case "createDocOutlet":
      return (<CreateDocOutlet />);
    case "templateSetup":
    case "templateText":
    case "templateSaveChoices":
    case "templateTitleLayout":
    case "templateTitleEdit": 
      return (<TemplateOutletID />);  
    // Switch for dashboard and profile pages
    case "share":
      return (<Share />);
    case "newuser":
      return <OnBoarding />;
    case "profile":
      return <Profile />;
    case "welcome":
    default:
      return (
        <NotificationsDataStoreProvider>
          <Dashboard />
        </NotificationsDataStoreProvider>
      );
  }
}
 
export default function Wrapper() {
  // TopDataStore maintians user credentials and current page
  const { topData, setTopData } = useTopDataStore();
  const dupCheckRef = useRef(true);
  
  // Check the status of the user's credentials when a timer goes or page changes
  // Consider not checking credentials when the page changes
  useEffect(() => {
    if (dupCheckRef.current) {
      let interval = null;
      interval = setInterval(() => {
        if (topData.AccessKeyId) {
          const url = topData.endpoint + "/status?" +
            "email=" + topData.userEmail + 
            '&userId=' + topData.userId + 
            '&Key=' + topData.AccessKeyId + 
            '&Secret=' + topData.SecretAccessKey +
            '&nonce=' + topData.nonce + 
            '&groupName=' + topData.groupName;
          fetch(url, {
            method : "GET",
            cache : "no-cache",
            headers : {
              Authorization : topData.token,
            }      
          })
          .then((res) => {
            if (401 === res.status) { 
              res.json().then((data) => {

                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
                // clearInterval ends the credential check
                clearInterval(interval);
                // Returning to the initial state clears TopData and logs the user out
                setTopData(initialState);          
              })
            }
            else if (200 === res.status) {
              res.json().then((data) => {
                if ("offline" === data.status) {
                  if ("offline" !== topData.page) {
                    // clearInterval ends the credential check
                    clearInterval(interval);
                    // Redirect to the offline page; essentially logs the user out
                    setTopData({
                      ...topData,
                      page : "offline",
                    });      
                  }
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });      
        } 
      }, 600000); // Set the time interval to check user crentials here
    
      return () => {
        // clearInterval stops the credential check after check is confirmed
        clearInterval(interval);
      }    
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topData.page]); // topData.groupName is here for future use to support changing workspaces.
  
  return (
    <Container fluid>
      <Row id="sidebarRow">
        <HorizNav />
      </Row>
      <Row className="contentBgrd">
        <Content />
      </Row>
    </Container>
  );
  
}
