import './App.css';
import { useEffect, useRef } from "react";
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useWorkspaceDataStore } from "./WorkspaceDataStoreProvider";
import { Container, Row, Col, 
  Button, Spinner, Form, Accordion } from 'react-bootstrap';

function Sections() {
  const { templateData, setTemplateData } = useTemplateDataStore();
  
  // The included list can be whatever list is passed through find_subsection
  // ** Don't spend a lot of time on this function as I'm not sure it 
  // will carry over in the same way to the revised UX workflow**
  const include_subsections = (s, included) => {
    s.sub_sections.forEach((ss) => {
      if (ss && ss.id) {included.push(ss.id)}
      if (ss && ss.sub_sections) {
        include_subsections(ss, included);
      }
    });
  };
  
  // Helper function to find all subsections of a given section and
  // return them by adding them to a provided array
  /* 
      BACKEND: Shouldn't have to iterate, think through if there's a 
      simpler way to send this id to database and have the process be done.
  */
  const find_subsection = (subsections, id, included) => {
    subsections.forEach((s) => {
      // TO DO: Fix to eliminate redundancy in line below 
      // If the subsection has an id that matches the provided id?
      if (s && id && s.id && id === s.id) {
        included.push(id);
        // Include all subsections of s in the list as well
        if (s.sub_sections) {
          include_subsections(s, included)
        }
      }
      // If the parent section (s) doesn't match the id, 
      // check if any of the subsections match the id.
      else if (s && s.sub_sections) {
        find_subsection(s.sub_sections, id, included);
      }
    });
  };
  
  // Counts the number of sections selected (including new and existing)
  // Add all sections to the newSections array (TO DO: Why?)
  const recurse_count = (sections, newSections) => {
    let count = 0;
    // Compare the section id against the selected array in templateData
    sections.forEach((s) => {
      if (s.id) {
        if (templateData.selected.includes(s.id)) {
          count++;
        }
        // Call the function again for all subsections
        if (s.sub_sections) {
          // Dummy empty list ot stand in for newSections
          const sub = [];
          recurse_count(s.sub_sections, sub);
          // After iteration, reassigns the subsections moved to sub 
          // in the recursive function back to the sub_sections of s
          s.sub_sections = sub; 
        }        
      }      
    });
    
    // Adds a count of how many subsections each section has. 
    // Save this to the newSections provided array
    sections.forEach((s) => {
      if (s.id) {
        s.count = count;
        newSections.push(s);     
      }
    });
  };
  
  // Every time that new sections are added to the selection, update the 
  // template_details and ensure that selections are correctly included
  useEffect(() => {
    // If there are templateDetails in the template data store, 
    // iterate through the sections in template_details 
    if (templateData.template_details && templateData.template_details.length) {
      templateData.template_details.forEach((s) => {
        if (s.sub_sections) {
          const sub = []
          // Add subsections to the subsection list if they match the section's id
          find_subsection(templateData.template_details, s.id, sub);
          // On tracks ?? 
          let on = false;
          // If the subsection id is in the included list turn `on` to true (? why)
          sub.forEach((ssid) => {
            if (ssid && templateData.selected.includes(ssid) && ssid !== s.id) { 
              on = true };
          });
          // This adds a parent section to the selected list if any of its 
          // subsections are included
          if (on && !templateData.selected.includes(s.id)) {
            setTemplateData({
              ...templateData,
              selected : [...templateData.selected, s.id], 
            });
          }
          // If the parent section is in the selected list and the subsection
          // is not, remove the subsection from the selected list 
          else if (!on && templateData.selected.includes(s.id)) {
            const newList = templateData.selected.reduce((prev, curr) => {
              if (curr !== s.id && curr) {
                prev.push(curr);
              }
              return prev;
            }, []);
            
            setTemplateData({
              ...templateData,
              selected : newList,
            });
          }
        }
      });    
      
      // Iterate through the parent sections and add to count 
      // if they are included in the selected list
      let count = 0;
      templateData.template_details.forEach((s) => {
        if (templateData.selected.includes(s.id)) {
          count++;
        }
      });
      // TO DO: Confirm if this is correct: the count is being attached to the 
      // section in a new for loop, so all of them will contain the same count
      // ?? Not sure why we do this
      const newDetails = [];
      templateData.template_details.forEach((s) => {
        s.count = count;
        
        if (s.sub_sections) {
          const sub = [];
          recurse_count(s.sub_sections, sub);
          s.sub_sections = sub;
        }
        
        newDetails.push(s);
      });
      // Update the template_details with the updated subsections
      // All that would be updated is the counts? Why update this?
      setTemplateData({
        ...templateData,
        template_details : newDetails
      });
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData.selected]);
  
  // Update the child section list if an item in the parent section list is updated
  const selectUnselect = (e) => {
    
    const id = e.target.name;
    const included = [];
    find_subsection(templateData.template_details, id, included);
        
    if (templateData.selected.includes(id)) {
      const newList = templateData.selected.reduce((prev, curr) => {
        if (curr && !included.includes(curr)) {
          prev.push(curr);
        }
        return prev;
      }, []);
            
      setTemplateData({
        ...templateData,
        selected : newList,
      });
    }
    else {
      setTemplateData({
        ...templateData,
        selected : [...templateData.selected, ...included], 
      });
    }
  };

  // Creates the label including a prefix (which includes proper numbering
  // for the subsections), and adds the selected items to the child template list
  // TO DO: Give this a more meaningful title. What does showUnchecked do?
  const recursiveSubSections = (prefix, sectionList, runningOutputList, showUnchecked) => {
    let thisSublevel = 1;
    sectionList.forEach((s) => {
      if (s && s.id && s.name) {
        const labelName = String(prefix) + "." + String(thisSublevel) + ". " + s.name;
        if (showUnchecked || templateData.selected.includes(s.id)) {
          runningOutputList.push(
            // TO DO: Get rid of the random variable for something more meaningful
            <div key={s.id + Math.floor(Math.random() * 100)} style={{marginBottom:5}}>
              <Form.Check className="assembleForm"
                inline
                label={labelName}
                name={s.id}
                type="checkbox"
                checked={templateData.selected.includes(s.id)}
                onChange={selectUnselect}
              />
            </div>
          );              
        }

        if (s.sub_sections) {
          const addToList = recursiveSubSections(
            String(prefix)+"."+String(thisSublevel), 
            s.sub_sections, runningOutputList, showUnchecked);
          addToList.forEach((s) => {
            sectionList.push(s);
          });
        }
      }      
      thisSublevel++;
    });
    return runningOutputList;
  };
  
  // TO DO: Give this a more meaningful name. 
  // showUnchecked is a binary that tells the list to show any unchecked selections 
  // in the parent list as well as the selected ones. 
  const column = (showUnchecked) => {
    
    // showUnchecked being true creates a list of all items in the parent including subsections
    // showUnchecked being false creates a list of only the selected items from the parent
    const outof = showUnchecked ? 2 : 1;
    
    const sectionList = [];
    for (let i=0; i<(templateData.template_details.length); i++) {
      const thisSection = templateData.template_details[i];
      if (thisSection && thisSection.id && thisSection.name) {
        const labelName = String((i+1)) + ". " + templateData.template_details[i].name;
        // Define layout for the child template "TOC" of included sections
        if (showUnchecked || templateData.selected.includes(templateData.template_details[i].id)) {
          sectionList.push(
              <Accordion.Item 
                  eventKey={templateData.template_details[i].id + String(outof)} 
                  key={templateData.template_details[i].id + String(outof)}
              >
                <Accordion.Header>
                  <Form.Check className="assembleForm"
                    inline
                    label={labelName}
                    name={templateData.template_details[i].id}
                    type="checkbox"
                    checked={templateData.selected.includes(templateData.template_details[i].id)}
                    onChange={selectUnselect}
                  />
                </Accordion.Header>
                {
                  thisSection.sub_sections ?
                    <Accordion.Body>
                      {recursiveSubSections((i+1), thisSection.sub_sections, [], showUnchecked)}
                    </Accordion.Body>                  
                  : ""
                }
              </Accordion.Item>
          );              
        }
        
      }
    }  
    return sectionList;
  };

  if (templateData.template_details && templateData.template_details.length) {    
    return (
      <>
        <Row>
          <Col xs={8}>
            <Accordion className="parentSections">
              {column(true)}
            </Accordion>
          </Col>
          <Col xs={4}>
            <Accordion>
              {column(false)}
            </Accordion>
          </Col>
        </Row>
      </>
    );
  }
  else {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />                        
    );
  }
}

// TemplateSetup is the table of contents creation part of the template design process. Here, the outline of the 
// parent template is displayed, and selections are made as to what to include in the new template.
// In revising the template workflow, this page will have significant changes so we can limit adjustments to this for now.
export default function TemplateSetup() {
  const { topData, setTopData } = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData } = useWorkspaceDataStore();

  const dataFetchingRef = useRef(false);

  // Create a new section
  const addSection = () => {
    // Create an id for the new section and add that ID to the list of selected sections.
    const num = templateData.newSectionNum + 1;
    const sel = [ ...templateData.selected, `newSection-${num}`];

    // This is the same function as that in Sections
    const recurse_count = (sections, newSections) => {
      let count = 0;
      sections.forEach((s) => {
        if (s.id) {
          if (sel.includes(s.id)) {
            count++;
          }
          if (s.sub_sections) {
            const sub = [];
            recurse_count(s.sub_sections, sub);
            s.sub_sections = sub;
          }        
        }      
      });
      // Investigate why this is happening
      sections.forEach((s) => {
        if (s.id) {
          s.count = count;
          newSections.push(s);     
        }
      });
    };
    
    // Generate information for the new section and add it to the bottom of the template
    // Note: I moved template_details up before the new section.
    const det = [ ...templateData.template_details, {
        id : `newSection-${num}`,
        version : 1,
        name : 'Newly Added Section',
        sub_sections : [],
        count : 0,
        html : "",
        guidance : "",
      }];
      
    let count = 0;
    det.forEach((s) => {
      if (sel.includes(s.id)) {
        count++;
      }
    });
    const newDetails = [];
    det.forEach((s) => {
      s.count = count;
      
      if (s.sub_sections) {
        const sub = [];
        recurse_count(s.sub_sections, sub);
        s.sub_sections = sub;
      }
      
      newDetails.push(s);
    });
    
    
    setTemplateData({
      ...templateData,
      template_details : newDetails,
      newSectionNum : num,
      selected : sel,
      frontMatter : templateData.frontMatter || templateData.frontMatterOptions,
      lines : templateData.lines || [],
    });
  };
   
  useEffect(() => {
    if (!dataFetchingRef.current && (!templateData.selected || !templateData.selected.length)) {
      dataFetchingRef.current = true;
      
      const frontMatterOptions = ['titlePage', 'tableOfContents', 'synopsis', 'versions', 'abbreviations', 
      'schematic', 'signaturePages', 'complianceStatement', 'scheduleOfEvents'];
      
      if (templateData.template_id || templateData.draft_id) {
        const url = topData.endpoint + "/template?" + 
          "email=" + topData.userEmail + 
          "&template_id=" + templateData.template_id + 
          "&draft=" + templateData.draft_id + 
          '&Key=' + topData.AccessKeyId + 
          '&Secret=' + topData.SecretAccessKey + 
          '&nonce=' + topData.nonce + 
          '&groupName=' + topData.groupName; 
        fetch(url, {
          method : "GET",
          cache : "no-cache",
          headers : {
            Authorization : topData.token,
          }      
        })
        .then((res) => {
          if (200 === res.status) {
            res.json().then((data) => { 
              console.log("Retrieved template data", data.details);
              // Search the template list in workspaces to find the template name
              const templateName = workspaceData.liveTemps.reduce((prev, curr) => {
                if (curr.id === templateData.template_id) {
                  return curr.name;
                }
                else {
                  return prev;
                }
              }, '');
              
              // If a frontMatter selection has been made, retain that. Otherwise use the default selections
              data.frontMatter = data.frontMatter.length ? data.frontMatter : frontMatterOptions;
            
              // IF there are sections included in the template
              if (data.details.selected && data.details.selected.length) { 
                // If changes have been made to the template selections             
                if (templateData.updated && templateData.updated.id === templateData.template_id && 
                  (templateData.updated.when + 20000) > Date.now()) {
                  // user just submitted a save to this template and that save will not yet 
                  // be complete on the backend, so use what we already have. 
                  const details = workspaceData.liveTemps.reduce((prev, curr) => {
                    if (curr.id === templateData.template_id) {
                      return curr.sections;
                    }
                    else {
                      return prev;
                    }
                  }, []);
                  setTemplateData({
                    ...templateData,
                    template_details : details,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                       
                }
                else {
                  setTemplateData({
                    ...templateData,
                    template_details : data.details.content,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                     
                }
                dataFetchingRef.current = false;                     
              }
              else {
                const collection = [];
                const allSectionIds = (sections) => {
                  return sections.forEach((s) => {
                    if (s.id) { 
                      if (!collection.includes(s.id)) {
                        collection.push(s.id);                       
                      }
                    }
                    
                    if (s.sub_sections && s.sub_sections.length) {
                      allSectionIds(s.sub_sections, collection);
                    }
                  });
                };
                
                allSectionIds(data.details);
              
                setTemplateData({
                  ...templateData,
                  template_details : data.details,
                  selected : collection,
                  collapsed : [],
                  frontMatter : data.frontMatter,
                  lines : data.lines,
                  name : templateName,
                  frontMatterOptions : frontMatterOptions,
                });     
              }          
            });
          }
          else {
            res.json().then((data) => {        
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Error accessing templates.'); 
              }    
            })      
          }
        })
        .catch((err) => {
          console.error(err);
          alert('Error accessing templates.');
        });      
      }
      else {
        // For new template, start by adding a section
        dataFetchingRef.current = false;                     
        addSection();   
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);  
  
  // Add or remove selected section to the list of frontmatter per check on form
  const addFrontMatter = (event) => {
    const name = event.target.name;
    if (templateData.frontMatter.includes(name)) {
      const updated = templateData.frontMatter.reduce((prev, curr) => {
        if (curr !== name) {
          prev.push(curr);
        }
        return prev;
      }, []);
      
      setTemplateData({ ...templateData, frontMatter: updated });
    }
    else {
      const updated = [ ...templateData.frontMatter, name ];
      setTemplateData({ ...templateData, frontMatter: updated });
    }
  };

  const gotoText = (e) => {
    e.preventDefault();

    let max = 0;
    const findNew = (sections) => {
      sections.forEach((s) => {
        if (s.id) {          
          const m = s.id.match(/newSection-(\d+)/);
          if (m) {
            if (parseInt(m[1]) > max) {
              max = parseInt(m[1]);
            }            
          }
          if (s.sub_sections) {
            findNew(s.sub_sections);
          }
        }
      });
    };
    findNew(templateData.template_details);
    setTemplateData({
      ...templateData,
      newSectionNum : max,      
    });

    setTopData({
      ...topData,
      page : 'templateText',
    });
  };

  // Define page layout
  return (
    <Container className="subPage" style={{"height":"1200"}}>
      <Row className="subPageRow">
        <h4 className="pageLevel2Head">
          Standard Pages
        </h4>
        <Row id="defaultSections">          
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"6rem"}}>            
              <Form.Check
                  inline
                  label="Title Page"
                  name="titlePage"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("titlePage")}
              />
              <Form.Check
                  inline
                  label="Table of Contents"
                  name="tableOfContents"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("tableOfContents")}
              />
              <Form.Check
                  inline
                  label="Synopsis"
                  name="synopsis"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("synopsis")}
              />
            </Col>
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"7rem"}}>            
              <Form.Check
                  inline
                  label="Amendments"
                  name="amendments"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("amendments")}
              />
              <Form.Check
                  inline
                  label="Abbreviations"
                  name="abbreviations"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("abbreviations")}
              />
              <Form.Check
                  inline
                  label="Schematic"
                  name="schematic"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("schematic")}
              />
            </Col>
            <Col xs={{offset: 1, span: 4}} style={{"paddingRight":"1rem"}}>            
              <Form.Check
                  inline
                  label="Signature Page"
                  name="signaturePages"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("signaturePages")}
              />
              <Form.Check
                  inline
                  label="Statement of Compliance"
                  name="complianceStatement"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("complianceStatement")}
              />
              <Form.Check
                  inline
                  label="Schedule of Events"
                  name="scheduleOfEvents"
                  type="checkbox"
                  onChange={addFrontMatter}
                  checked={templateData.frontMatter && templateData.frontMatter.includes("scheduleOfEvents")}
              />
            </Col>
        </Row>
      </Row>
      <Row className="subPageRow">
        <Row>
          <Col xs="8">
            <h4 className="pageLevel2Head" id="parentSectionsLabel">
              Parent Sections
            </h4>
          </Col>
          <Col >
            {
              templateData.selected.length ? 
                <div className="d-grid gap-2">
                  <Button 
                    variant="primary" 
                    className="intNavButton" 
                    id="sectionsNextButton"
                    onClick={gotoText}>
                      Next
                  </Button>
                </div>              
              : ""
            }
          </Col>
        </Row>
        <Row id="parentSections">
            <Sections />  
        </Row>
      </Row>
    </Container>
  );
}