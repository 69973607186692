import { createContext, useState, useContext } from "react";

/*
    The Workspace Data Store holds data about the list of templates, documents, 
    and variables available to a user. Data about individually loaded templates, 
    documents, or variables are stored in DocumentDataStore, TemplateDataStore, 
    and WorkspaceDataStore.
*/
const WorkspaceDataStore = createContext();
export const useWorkspaceDataStore = () => useContext(WorkspaceDataStore);

export const initialState = {

  varSets : [], // TO DO: This should just be a list and the full info should be in VariableDataStore
  varDefns : [], // TO DO: This should just be a list and the full info should be in VariableDataStore
  lines : [], // Still not sure what this is, preventing errors by retaining
  docs : [], // All documents accessible in workspace
  companyDocuments : [], // Unclear how this differs from docs
  docs_accessed : undefined, // Saves the time the document list was last updated// Workspace templates
  liveTemps : undefined, // All live templates accessible in workspace
  draftTemps : undefined, // All drafted templates accessible in workspace

};

export default function WorkspaceDataStoreProvider({children}) {
  const [workspaceData, setWorkspaceData] = useState(initialState);
  
  return (
    <WorkspaceDataStore.Provider value={{ workspaceData, setWorkspaceData }}>
      {children}
    </WorkspaceDataStore.Provider>
  );
} 
