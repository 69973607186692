import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import { useWorkspaceDataStore } from "./WorkspaceDataStoreProvider";
import { useEffect } from "react";
import { Container, Row, Col, Button, Spinner, Form } from 'react-bootstrap';
 
export default function TemplateSaveChoices() {

  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { topData, setTopData } = useTopDataStore();

  const refreshTemplateLists = (saveType) => {
    const url =
      topData.endpoint + "/template?" +
        "email=" + topData.userEmail +
        "&Key=" + topData.AccessKeyId +
        "&Secret=" + topData.SecretAccessKey +
        "&nonce=" + topData.nonce +
        "&groupName=" + topData.groupName;
    fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: topData.token,
      },
    })
    .then((res) => {
      if (401 === res.status) {
        res.json().then((tempsData) => {
        if (tempsData.message) {
            setTopData({
              ...topData,
              loginWarning: tempsData.message,
            });
          } else {
          setTopData({
            ...topData,
            loginWarning: "Error retrieving workspace templates.",
          });
      }
      });
    } else if (200 === res.status) {
        res.json().then((tempsData) => {
          setWorkspaceData({
            ...workspaceData, 
            liveTemps: tempsData['templates'],
            draftTemps: tempsData['drafts'],
          });
          alert("Template save successful!");
          // refreshOpenTemplate();
          // Redirecting to the Template List until a better process 
          // for loading the changes after a save is set-up
          setTopData({
            ...topData,
            loading: false,
            isSaving: false,
            loginWarning: "",
            page: "templates"
          });
        });
      }
    })
    .catch((err) => {
      console.log(err);
      alert('Error retrieving updated template lists.');
      setTopData({...topData, loading : false,});
    });
  } 

  /*
  const refreshOpenTemplate = () => {
    // Reload id and title of open template after a save is made
    // TO DO WITH BACKEND HELP: Need to be able to pull the ID from the just saved 
    // template and refresh the setup page to match that information
  }
*/

  // Iterate through each template section to identify what needs to be saved
  const process_sections = (sections, status) => {
    const linearize = (tree, list, parent) => {
      let i = 1;
      tree.forEach((s) => {
        if (!s['id'] || !templateData.selected.includes(s['id'])) {
          return;
        }
        
        if (s['id'].match(/^newSection/)) {
          s['changed'] = true;
        } 
        else {
          s['changed'] = false;
        }
        
        s['parent'] = parent;
        if ('top' === parent) {
          s['order'] = i;
          i++;
        }
        s['children'] = [];
        
        if (s['sub_sections'] && s['sub_sections'].length) {
          const subs = s['sub_sections'].reduce((prev, ss) => {
            if (ss['id'] && templateData.selected.includes(ss['id'])) {
              s['children'].push(ss['id']);
              return [ ...prev, ss ];
            }
            else {
              return prev;
            }
          }, []);
          s['sub_sections'] = subs;
          
          linearize(s['sub_sections'], list, s['id']);
        }
        list.push(s);        
      });
    }
      
    const list = [];

    linearize(sections, list, 'top');
        
    const findPart = (sections, part, id) => {
      return sections.reduce((prev, s) => {
        if (s['id'] === id) {
          return s[part] || "";
        }
        else if(s['sub_sections']) {
          return findPart(s['sub_sections'], part, id) || prev;
        }
        else {
          return prev;
        }
      }, '');
    }
    
    const findSection = (id, sections) => {
      let found = false;
      return sections.reduce((prev, curr) => {
        if (found) {
          return prev;
        }
        else if (curr.id === id) {
          found = true;
          return curr;
        }
        else if (curr.sub_sections && curr.sub_sections.length) {
          const s = findSection(id, curr.sub_sections);
          if (s.id) { return s; }
          else { return prev; }
        }
        else {
          return prev;
        }        
      }, {});
    }
    
    const unalteredDetails = () => {
      return workspaceData.liveTemps.reduce((prev, curr) => {
        if (curr.id === templateData.template_id && curr.version === templateData.template_version) {
          return curr;
        }
        else {
          return prev;
        }
      }, {sections: []});
    }
    
    const differentSubSections = (s) => {
      if (!s.id) { return false; }
    
      const details   = unalteredDetails();
      const unaltered = findSection(s.id, details.sections);
      
      if (!s.sub_sections) { s.sub_sections = []; }
      if (!unaltered.sub_sections) { unaltered.sub_sections = []; }
      
      if (s.sub_sections.length !== unaltered.sub_sections.length) {
        return true;
      }
      else if (0 === s.sub_sections.length) {
        return false;
      }
      else {
        let i = 0;
        return s.sub_sections.reduce((prev, curr) => {
          const same = (curr.id === unaltered.sub_sections[i].id);
          i++;
          if (!same) { 
            return true; 
          }
          else { return prev; }
        }, false);
      }
    }
    
    const different = (a, part, id) => {
      a = a || '';
      
      // need to find the unaltered version, as last downloaded, for comparison
      const details = unalteredDetails();
      
      let b = findPart(details.sections, part, id);
    
      a = a.replace(/^\s+|\s+$/g, '');
      b = b.replace(/^\s+|\s+$/g, '');
      a = a.replace(/\s+/g, ' ');
      b = b.replace(/\s+/g, ' ');
      return (a !== b);
    };
    
    const updated = [];
    
    const process_next_leaf = (list) => {
      const s = list.reduce((prev, curr) => {
        if (prev) { return prev; }
        else {
          if (curr.children && curr.children.length) {
            return prev;
          }
          else {
            if (!curr['changed'] && differentSubSections(curr)) {
              curr['changed'] = true;
            }
            else if ( !curr['changed'] && (different(curr['guidance'], 'guidance', curr.id) || different(curr['html'], 'html', curr.id) || different(curr['name'], 'name', curr.id) ) ) {
              curr['changed'] = true;
            }
            
            return curr;
          }
        }
      }, false);
      
      if (s) {
        if (s['changed']) {
          const variable_sets = [];
          const re = /data-tag="(\w+)\./g;
          let   m  = [];
          while ((m = re.exec(s.html)) !== null) {
            variable_sets.push(m[1]);
          }
          
          if ('version' === status) {
            setTemplateData({
              ...templateData,
              versionSaving : `saving ${s.name} section...`,
              loading2 : true,
            });         
          }
          else {
            setTemplateData({
              ...templateData,
              loading3 : true,
              branchSaving : `saving ${s.name} section...`,
            });         
          }

          fetch(topData.endpoint + '/section', {
            method : "POST",
            body : JSON.stringify({
              email : topData.userEmail,
              token : topData.token,
              Key : topData.AccessKeyId,
              Secret : topData.SecretAccessKey,
              nonce : topData.nonce,
              groupName : topData.groupName,
              section : s,
              vsets : variable_sets,
            })
          })
          .then((response) => {
            if (200 === response.status) {
              s.changed = false;
              response.json().then((data) => {
                if ('top' === s.parent) {
                  s.id = data.id;
                  s.version = data.version;
                  s.updated_at = data.updated_at;
                  updated.push(s); 
                }
                else {
                  list.forEach((p) => {
                    if (p.id === s.parent) {
                      const children = p.children.reduce((prev, curr) => {
                        if (curr !== s.id) {
                          prev.push(curr);
                        }
                        return prev;
                      }, []);
                      p.children = children;
                      
                      p.changed = true;
                      p.sub_sections.forEach((ss) => {
                        if (ss.id === s.id) {
                          ss.version = data.version;
                          ss.id = data.id;
                        }
                      });                    
                    }
                  });
                }
              
                const decrementedList = list.reduce((prev, curr) => {
                  if (curr.id !== s.id) {
                    prev.push(curr);
                  }
                  return prev;
                }, []);
              
                process_next_leaf(decrementedList);
              });
            }
            else {
              response.json().then((data) => {
    
                if (data.message) {
                  alert(data.message);
                  setTopData({...topData, loading : false,});
                }
                else {
                  alert('Error saving template.'); 
                  setTopData({...topData, loading : false,});
                }       
              })            
            }
          });               
        }
        else {
          if ('top' === s.parent) {
            updated.push(s);
          }
          else {
            list.forEach((p) => {
              if (p.id === s.parent) {
                const children = p.children.reduce((prev, curr) => {
                  if (curr !== s.id) {
                    prev.push(curr);
                  }
                  return prev;
                }, []);
                p.children = children;
              }
            });
          }
        
          const decrementedList = list.reduce((prev, curr) => {
            if (curr.id !== s.id) {
              prev.push(curr);
            }
            return prev;
          }, []);
        
          process_next_leaf(decrementedList);
        }           
      }
      else {
        updated.sort((a,b) => {if (a['order'] > b['order']) { return 1; } else { return -1; }});
                  
        if ('version' === status) {
          setTemplateData({
            ...templateData,
            loading2 : true,
            versionSaving : 'final step, saving overall template structure...',
          });         
        }
        else {
          setTemplateData({
            ...templateData,
            loading3 : true,
            branchSaving : 'final step, saving overall template structure...',
          });         
        }

        fetch(topData.endpoint + "/template", {
          method : "PUT",
          body : JSON.stringify({
            email : topData.userEmail,
            userId : topData.userId,
            token : topData.token,
            template : {
              sections : updated,
              lines    : templateData.lines,
            },
            id : templateData.template_id,
            version : templateData.template_version,
            selected : templateData.selected,
            draft_id : templateData.draft_id,
            name : templateData.newName,
            description : templateData.description,
            status : status,
            Key : topData.AccessKeyId,
            Secret : topData.SecretAccessKey,
            nonce : topData.nonce,
            groupName : topData.groupName,
            frontMatter : templateData.frontMatter, 
          })
        })
        .then((response) => {
          if (200 === response.status) {
              response.json().then((data) => { 
                // new -> add new to list
                // version -> update version. 
                
                let templates = workspaceData.liveTemps;
                let draft_id = templateData.draft_id;
                let drafts = workspaceData.draftTemps;
              
                if ('new' === status) {
                  templates = [ ...workspaceData.liveTemps, {
                    id: data.id,
                    version: data.version,
                    name: templateData.newName,
                    description: templateData.description,
                    sections: templateData.template_details
                  } ];
                  
                  draft_id = "";
                }
                else if ('version' === status) {
                  templates = workspaceData.liveTemps.map((t) => {
                    if (t.id === data.id) {
                      return { ...t, version: data.version, sections: updated, }
                    }
                    else {
                      return t;
                    }
                  });
                  
                  drafts = workspaceData.draftTemps.reduce((prev, curr) => {
                    if (curr.draft_id !== draft_id) {
                      prev.push(curr);
                    }
                    
                    return prev;
                  }, []);
                  
                  draft_id = "";
                }

                setWorkspaceData({
                  ...workspaceData,
                  liveTemps : templates,
                  draftTemps : drafts,
                });

                setTemplateData({
                  ...templateData,
                  selected : [],
                  template_details : [],
                  draft : draft_id,
                  footer : false,
                  updated : {
                    id : templateData.template_id,
                    when : Date.now()
                  },
                  versionSaving : "",
                  branchSaving  : "",
                });  

                refreshTemplateLists(status);
              });      
          }
          else {
            response.json().then((data) => {
    
              if (data.message) {
                alert(data.message);
                setTopData({...topData, loading : false,});
              }
              else {
                alert('Error saving template.');
                setTopData({...topData, loading : false,});
              }        
            })            
          }
        });                    
      }
    };
        
    process_next_leaf(list);
  };

  // Create a new version for the save
  const saveNew = (event) => {
    event.preventDefault();
    
    if (!templateData.newName) {
      alert('Please indicate the name for the new Template');
      return false;
    }    
    else {
      setTemplateData({
        ...templateData, 
        loading3 : true,
        branchSaving : 'compiling changes and contacting server...',
        name : templateData.newName,
      });

      // Call to function to save each section to backend
      process_sections(templateData.template_details, 'new');
    }
  };
  
  const saveVersion = (event) => {
    event.preventDefault();
    
    setTemplateData({
      ...templateData, 
      loading2 : true,
      versionSaving : 'compiling changes and contacting server...',
    });
    
    process_sections(templateData.template_details, 'version');
  };
  
  // Button action for "Save as Draft" 
  const saveDraft = (event) => {
    event.preventDefault();
    
    setTemplateData({
      ...templateData,       
      loading1 : true,
    });
    
    // Call the /template/PUT with no parameters.
    const url = topData.endpoint + "/template";
    fetch(url, {
      method : "PUT",
      body : JSON.stringify({
        email : topData.userEmail,
        token : topData.token,
        template : {
          sections : templateData.template_details,
          lines : templateData.lines,
        },
        id : templateData.template_id,
        draft_id : templateData.draft_id,
        version : templateData.template_version,
        status : 'draft',
        selected : templateData.selected,
        name : templateData.newDraftName,
        description : templateData.newDraftDescription,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
        frontMatter : templateData.frontMatter,
      })
    })
    .then((response) => {
      if (200 === response.status) {
        response.json().then((data) => {  
          refreshTemplateLists("draft");
        });
      }
      else {
        response.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
            setTopData({...topData, loading : false,});
          }
          else {
            alert("Error saving new draft."); 
            setTopData({...topData, loading : false,});
          }        
        })            
      }
    })
  };

  const setValue = (event) => {
    setTemplateData({ 
      ...templateData, 
      [event.target.name]: event.target.value });
  };  
  
  useEffect(() => {
    const description = workspaceData.liveTemps.reduce((prev,curr) => {
      if (curr.id === templateData.template_id && parseInt(curr.version) === parseInt(templateData.template_version)) {
        return curr.description;
      }
      else {
        return prev;
      }
    }, "");

    setTemplateData({ 
      ...templateData, 
      description: (templateData.draftDescription || description), 
      draftDescription : (templateData.draftDescription || description),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData.liveTemps]); 
  // Define page layout
  return (
    <Container className="subPage">
      <Row className="subPageRow">
        <Col md="3">
          {/*Save template as a draft using the name in Form.Control newDraftName with description from Form.Control newDraftDescription */}
          <Button 
            className="dataAddInstance saveTempBtn" 
            onClick={saveDraft}
          >
            {
              templateData.loading1 ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />        
              :
              <>Save as Draft</>              
            }
          </Button> 
        </Col>
        <Col md="7">
          <Form.Control 
            type="text" 
            placeholder={"Untitled draft"}
            name="newDraftName" 
            onChange={setValue} 
            className="dataLiveType saveLive" 
          />
          <Form.Control 
            type="text" 
            placeholder={"Description of untitled draft"} 
            name="newDraftDescription" 
            onChange={setValue} 
            className="dataLiveType saveLive" 
          />
          <p className="saveInstructions">
          <span className="draftLabel">Draft:</span> Your edited template will appear as a choice
          for editing when you log in, but will not appear for any other users. 
          </p>
        </Col>
      </Row>
      <Row className="subPageRow">
        <Col md="3">
          <Button 
              className="dataAddInstance saveTempBtn"
              onClick={saveNew}
            >
              {
                templateData.loading3 ? 
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />        
                :
                <>Save as New </>              
              }            
            </Button> 
        </Col>
        <Col md="7">
          <Form.Control 
            type="text" 
            placeholder="Untitled template" 
            name="newName" 
            className="dataLiveType saveLive" 
            onChange={setValue}  
          />
          <Form.Control 
            type="text" 
            placeholder="Description of untitled template" 
            name="description" 
            onChange={setValue}
            className="dataLiveType saveLive"  
          />
          <p className="saveInstructions">
            <span className="draftLabel">
              New:</span> Saving as a new template will make your template available for use in a document.</p>
        </Col>
      </Row>
      <Row className="subPageRow">
        <Col md="3">
          <Button 
            className="dataAddInstance saveTempBtn" 
            onClick={saveVersion}
          >
           {
              templateData.loading2 ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />        
              :
              <>Save as Version</>              
            }            
          </Button> 
          </Col>
          <Col md="7">
          <p className="saveInstructions"><span className="draftLabel">Version:</span> Will save a new version of the existing template. 
          Once saved, this latest version will appear as the default version of this template for any new documents 
          and for anyone wanting to edit this template. The previous version of the template 
          will continue to exist in the database, and any existing documents will continue to 
          use the version of the template they were created from.</p>
          </Col>
        </Row>   
      <Row style={{marginBottom: 35}}>
        <Col></Col>
        <Col>
          <div className="welcomeText">{templateData.versionSaving}</div>
        </Col>  
        <Col>
          <div className="welcomeText">{templateData.branchSaving}</div>
        </Col>        
      </Row> 
    </Container>
  );
}