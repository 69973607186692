import './Login.css';
import { Row, Col, Container, Button, Spinner,
  Form, FloatingLabel, Alert} from 'react-bootstrap';
import { ErrorCircle, LogoIpsum } from "./img/Vectors";
import { useTopDataStore } from "./TopDataStoreProvider";

export default function Login() {

  const { topData, setTopData } = useTopDataStore();
  
  // Sign in button event, sends login information though API Gateway to check credentials
  const sendLogin = (event) => {
    event.preventDefault();
    
    // Validate email address format
    if (!document.getElementById('floatingInput').value.match(/\w@\w/)) {
      setTopData({
        ...topData,
        loginWarning : "Please use a valid email address as your login.",
      });
      return false;
    }

    if (!document.getElementById('floatingPassword').value.match(/\S\S\S\S\S\S\S/)) {
      setTopData({
        ...topData,
        loginWarning : "Please enter your password.",
      });
      return false;
    }  
        
    setTopData({ ...topData, loading: true, });
    
    const url = topData.endpoint + "/login";
    fetch(url, {
      method : "POST",
      body : JSON.stringify({
        email: topData.userEmail, 
        password: topData.userPassword})
    })

    .then((res) => {
      if (400 === res.status) {
        res.json().then((data) => {
          console.log(data)
        });
        setTopData({
          ...topData,
          loginWarning : "Error sending email confirmation code",
        });
      }
      else if (401 === res.status) { 
        res.json().then((data) => {
          if (data.message) {
            if (data.message.includes('number of attempts')){
              const emailLocks = topData.lockedEmails;
              emailLocks.push(topData.userEmail);
              setTopData({
                ...topData, 
                userEmail     : "", 
                userPassword  : "",
                loginWarning : data.message,
                lockedEmails  : emailLocks,
              });
            }
            else{
              setTopData({
                ...topData, 
                userEmail     : "", 
                userPassword  : "",
                loginWarning : data.message,
              }); 
            }
          }
          else {
            setTopData({
              ...topData, 
              userEmail     : "", 
              userPassword  : "",
              loginWarning : "Error confirming login credentials.",
            });

          }       
        })
      }
      
      // If user credentials are confirmed, redirect to Confirm Login screen
      else if (200 === res.status) {
        res.json().then((data) => {
          setTopData({ 
            ...topData, 
            page      : "confirm",
            userId    : data.userId,
            groupName : data.groupName,
            loginWarning : "",
          });
        })
        .catch((err) => console.log(err));
      }      
    })
    .catch((err) => console.error(err));
  };
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setTopData({ ...topData, [key]: val});

  };

  // Define page layout
  return (
    <Container fluid className="loginCont">
    <div className="screen">
    <Row>
      <Col className="login-container align-middle">
        <Row className="loginBlock">
        <Col className="logoRow">
          <LogoIpsum />&nbsp;
        </Col>
        <Form onSubmit={sendLogin} className="formLogin">
          <>
          {
            (topData.loginWarning === "")  ? "" :
            <Alert className="login-alert" key={'danger'} variant={'danger'}>
              <ErrorCircle/>{topData.loginWarning}
            </Alert>
          }</>
          <h1 className="loginHead">Log into Kinetika by Asclepia</h1>
          <Form.Group className="form-floating text-nowrap overflow-x-hidden mt-3">
            <FloatingLabel 
              label="Email address"
            >
              <Form.Control 
                type="email"
                value={topData.userEmail} 
                className="form-control mb-3" 
                name="userEmail" 
                id="floatingInput" 
                placeholder="name@example.com" 
                onChange={formAction} 
                onBlur={formAction} 
                required 
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="form-floating overflow-x-hidden">
            <FloatingLabel 
              label="Password"
            >
              <Form.Control 
                type="password"
                value={topData.userPassword} 
                className="form-control" 
                name="userPassword" 
                id="floatingPassword" 
                placeholder="Password" 
                onChange={formAction} 
                onBlur={formAction}
                rows="1" 
                required 
              />
            </FloatingLabel>
          </Form.Group>
          <>
          {
            <Button onClick={sendLogin} 
              style={{background: "#FF9B54", borderColor:"#FF9B54"}} 
              size="lg" 
              className="log-in-button mt-4" disabled={(topData.lockLogin === true || topData.userPassword === "" || topData.userEmail === "" || topData.lockedEmails.includes(topData.userEmail) === true) ? true : false} >
              {topData.loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              {!topData.loading && "SIGN IN"}
            </Button>
          }</>
        </Form>
        </Row>
      </Col>
    </Row>
  </div>  
  </Container>  
  );
}